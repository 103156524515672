import React from 'react';
import OrganizationList from "../../components/OrganizationList";
import {Helmet} from "react-helmet";

const PerformingOrganization = _ => {

    let pathArray = window.location.pathname.split('/');
    const collection = pathArray[1] === 'performingartstheatre' ? 'HostingOrganization' : 'PerformingOrganization';
    return <div className={'organizations'}>
        <Helmet>
            <title>{'Theater – Freie Theater Hannover'}</title>
        </Helmet>
        <section className={'container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'fz-140 text-uppercase pb-5 mt-5 hyphenation'}>Freie Ensembles und Solokünstler*innen</h1>
                    <p>
                        Die freien Theater Hannover (FTH) - unvergleichlich ungleich!
                    </p>
                </div>
            </div>
        </section>
        <OrganizationList organizationType={collection}/>
    </div>
};

export default PerformingOrganization;

