

export const getImageUrl = path => {
  return (path && `${process.env.REACT_APP_API_URL}/${path.replace(/^\//, '')}`) || null;
};

export const getAuthUrl = auth => {
  return `${process.env.REACT_APP_API_URL}/api/public/auth`;
};

export const getCollectionUrl = collection => {
  return `${process.env.REACT_APP_API_URL}/api/collections/get/${collection}?token=${process.env.REACT_APP_API_TOKEN}`;
};

export const getBackendCollectionUrl = (collection,customToken) => {
  return `${process.env.REACT_APP_API_URL}/api/collections/get/${collection}?token=${customToken}`;
};
export const saveBackendCollectionUrl = (collection,customToken) => {
  return `${process.env.REACT_APP_API_URL}/api/collections/save/${collection}?token=${customToken}`;
};


export const getSingeleton = singleton => {
  return `${process.env.REACT_APP_API_URL}/api/singletons/get/${singleton}?token=${process.env.REACT_APP_API_TOKEN}`;
}
export const getDatesUrl = getDatesUrl => {
  return `${process.env.REACT_APP_API_URL}/api/public/dates`;
}
export const getImageThumbUrl = image => {
  return `${process.env.REACT_APP_API_URL}/api/cockpit/image/?token=${process.env.REACT_APP_API_TOKEN}`;
};

export const getRegionUrl = region => {
  return `${process.env.REACT_APP_API_URL}/api/regions/data/${region}?token=${process.env.REACT_APP_API_TOKEN}`;
};

export const getUserToken= token => {
  const  Token = sessionStorage.getItem('token');
/*  console.log(Token);*/
  if(Token!==null){
    const  userToken = JSON.parse(Token);
    if(typeof userToken.api_key !== "undefined"){
/*      console.log(userToken)*/
      return userToken
    }else{
      return null;
    }
  }else{
    return null;
  }
};



