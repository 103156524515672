import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {getCollectionUrl} from "../../utils";
import useHyphen from 'react-use-hyphen';
import {Helmet} from "react-helmet";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Image } from "@wbe/lazy-image"
import 'lite-vimeo-embed';
import Vimeo from "@u-wave/react-vimeo";

const OrganizationItem = _ => {
    let params = useParams();
    const _id = params.id;
    const [data, setData] = useState({});
    const [filterByID, setfilterByID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {Hyphen} = useHyphen();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                let pathArray = window.location.pathname.split('/');
                setfilterByID(pathArray[2]);
                const collection = pathArray[1] === 'performingartstheatre' ? 'HostingOrganization' : 'PerformingOrganization';
                const response = await fetch(getCollectionUrl(collection), {
                    mode: 'cors',
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        filter: {_id: pathArray[2]},
                        sort: {'startdate': -1},
                        populate: 1, // resolve linked collection items
                    })
                })
                const json = await response.json();
                setData(json.entries[0]);
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

    }, [_id]);

    function renderWYSIWG(strToDecode) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${strToDecode}`, 'text/html').body.textContent;
        console.log(decodedString);
        return decodedString;
    }
    function copyright(image) {
        function isNullOrUndefined(value) {
            return value === undefined || value === null;
        }

        if (isNullOrUndefined(image.meta)) {
            return false;
        } else {
            if (isNullOrUndefined(image.meta.title)) {
                return false;
            } else {
                return image.meta.title
            }
        }
    }
    function vimeoVideo(fthvideo,fthvideoThumb) {
        console.info('fthvideo',fthvideo)
        console.info('fthvideoThumb',fthvideoThumb)
        const videothumb=process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + fthvideoThumb.path + '&w=1920&h=1080&m=thumbnail&mime=image/webp&q=80&o=1';
        console.info('fthvideoThumb',videothumb)
        return (
            <div className={'vimeopreview p-3 p-lg-5'} >
                <img className={'img-fluid'} src="/static/images/placeholder.png" alt={'placeholder'} style={{backgroundImage:`url(${videothumb})`}} />
                {/*<lite-vimeo videoid={fthvideo}></lite-vimeo>*/}
                {/*<div className={'lite-vimeo-wrapper'} style={{backgroundImage:`url(${videothumb})`}}>*/}
                {/*    <lite-vimeo className={'lite-vimeo-holder'} videoid={fthvideo}>*/}
                {/*            <div className="ltv-playbtn">&nbsp;</div>*/}
                {/*    </lite-vimeo>*/}
                {/*</div>*/}
            </div>
        )
    }

    return <div className={'organization totop'}>
        <Helmet>
            <title>{data.name + ' – '+ process.env.REACT_APP_SITETITLE}</title>
        </Helmet>
        <section className={'container-fluid p-0 mt-0'}>
            <div className={'row g-0'}>
                <div className={'col-12 p-0'}>
                    <div className={'position-relative'}>
                        <div className={'text-white'}>
                            {(typeof data.image === "object" && data.image !== null) ?
                                <div className={'img-wrapper card-image'}>
                                    <Image
                                        srcPlaceholder={'/static/images/placeholder.png'}
                                        src={process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + data.image.path + '&w=1920&h=1080&m=thumbnail&mime=image/webp&q=80&o=1'}
                                        alt={data.name}
                                        width={1920}
                                        height={1080}
                                        className={'img-fluid'}
                                    />

{/*
                                    <div className={'copyright text-lowercase'}><small>©{(data.image.meta.title===''? data.name : data.image.meta.title)}</small></div>
*/}

                                    </div>
                                :
                                <span>&nbsp;</span>
                            }
                            <div className={'position-absolute card-title'}>
                                <h2 className={'h1 fz-140 text-uppercase hyphenation h-100 w-100 d-flex flex-column'}>
                                    <span className={'d-flex mb-auto mt-auto ms-auto me-auto text-center'}><b
                                        className={'d-inline-block mb-lg-5 pb-lg-5'}>{data.name}</b></span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={'mt-0 pt-0'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-9 col-lg-9 pt-5 pb-5'}>
                        <p className={'h6 text-uppercase'}>{data.name}</p>
                        <Hyphen>
                            <div className={'text-holder '}>
                            <p className={'hyphennation'} dangerouslySetInnerHTML={{__html: (renderWYSIWG(data.description))}}/>
                            {(data.city !== null) &&
                                <a className="btn btn-link ps-0" href={data.url}>{data.url}</a>
                            }
                            </div>
                        </Hyphen>
                    </div>
                    <div className={'col-md-3 col-lg-3 ps-5 pt-5 pb-5'}>
                        <p className={'h6'}>&nbsp;</p>
                        {(data.instagram!==null) &&
                            <a href={data.instagram} className={'mb-3 me-3 socialicons d-inline-block  d-md-block rounded-pill'}>
                                <svg className={'text-white'} width={'30px'} viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                            </a>
                        }
                        {(data.facebook!==null) &&
                            <a href={data.facebook} className={'mb-3 me-3 socialicons d-inline-block  d-md-block rounded-pill'}>
                                <svg className={'text-white'} width={'20px'} viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                            </a>
                        }
                        {(data.url!==null) &&
                            <a href={data.url} className={'mb-3 me-3 socialicons d-inline-block  d-md-block rounded-pill'}>
                                <svg className={'text-white'} width={'30px'} viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 21 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg>
                            </a>
                        }
                        {(data.email!==null) &&
                            <a href={'mailto:' + data.email} className={'mb-3 me-3 socialicons d-inline-block  d-md-block rounded-pill'}>
                                <svg  width={'30px'} className={'text-white'} viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                            </a>
                        }
                            <address className={'d-block small-address'}>

                                {(data.name!==null) &&
                                    <span>{data.name} <br/></span>
                                }
                              {(data.addressprefix!==null) &&
                                    <span>{data.addressprefix} <br/></span>
                           }
                            {(data.street!==null) &&
                                <span>{data.street}<br/></span>
                            }
                            {(data.zip!==null) &&
                                <span>{data.zip} </span>
                            }
                            {(data.city!==null) &&
                                <span>{data.city}<br/></span>
                            }
                                {(data.telephone!==null) &&
                                    <span>{data.telephone} <br/><br/></span>
                                }
                            <br /> <br />
                                {(typeof data.mediakit!=='undefined' && data.mediakit!==null) &&
                                    <a className={'text-black'} href={process.env.REACT_APP_API_URL + 'storage/uploads/' + data.mediakit.path}>Pressematerial</a>
                                }

                        </address>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <section className={'mt-0 pt-0 border-bottom border-3 border-white'}>
                <div className={'container-fluid bg-black'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                {(isLoading===false) &&
                                    <>
                                    {/*{(typeof data.fthvideo !== 'undefined' && data.fthvideo !== null) &&*/}
                                    {/*    <>{vimeoVideo(data.fthvideo,data.fthvideoThumb)}</>*/}
                                    {/*}*/}
                                        <Vimeo
                                            video={data.fthvideo}
                                            dnt={true}
                                            responsive={true}
                                            showTitle={false}
                                            showPortrait={false}
                                            showByline={false}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </section>
        <section className={'mt-0 p-0 bg-gray'}>
            {(typeof data.gallery !== 'undefined' && data.gallery !== null) &&
                <div className={'container-fluid p-0'}>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                    >
                        <Masonry>
                            {data.gallery.map((image, i) => (
                                <div
                                    className={'masonry-image position-relative'}
                                    key={'masonry-image' + i}
                                >
                                    <Image
                                        key={'masonry-image-img' + i}
                                        className={'img-fluid'}
                                        src={process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + image.path + '&w=630&h=420&m=crop&mime=image/webp&q=80&o=1'}
                                        alt={'Bild ' + i + ' – ' + data.name}
                                        style={{width: "100%", display: "block"}}
                                    />
                                    <div className={'copyright text-lowercase'}><small>©{(image.meta.title===''? data.name : image.meta.title)}</small></div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            }
        </section>
    </div>

};

export default OrganizationItem;

