import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {endOfYesterday, format} from "date-fns";
import {de} from "date-fns/locale";
import {getSingeleton} from "../../utils";
import {NavLink} from "react-router-dom";
import {Image} from "@wbe/lazy-image";


class Kontakt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            organizationType: props.organizationType,
            startdate: format(endOfYesterday(), 'yyyy-MM-dd', {
                locale: de
            })
        }
    }

    getData() {
        fetch(getSingeleton('about'), {
            mode: 'cors',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    populate: 1,
                }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    eventlist: data,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.eventlist.name + ' – Freie Theater Hannover'}</title>
                </Helmet>
                <section className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'fz-140 text-uppercase pb-5 mt-5 hyphenationhyphenation'}>Freie Theater Hannover</h1>
                        </div>
                        <div className={'col-lg-12 pb-5 mb-3'}>
                            <div className={'text-holder hyphennation'}
                                 dangerouslySetInnerHTML={{__html: this.state.eventlist.description}}></div>
                            {(this.state.loading === false) &&
                                <div className={'row pt-5'}>
                                    {
                                        this.state.eventlist.contact.map(contact => (
                                            <div className={'col-md-3 mb-3'}>
                                                <Image
                                                    srcPlaceholder={'/static/images/placeholder.png'}
                                                    src={process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + contact.value.image.path + '&w=201&h=250&m=thumbnail&mime=image/webp&q=60&o=1'}
                                                    alt={contact.value.name}
                                                    width={201}
                                                    height={250}
                                                    className={'img-fluid mb-3'}
                                                />
                                                <p> <b>{contact.value.role}</b><br />
                                                    {(contact.value.organization !== '') &&
                                                        <>
                                                            {contact.value.organization}<br/>
                                                        </>
                                                    }
                                                    {contact.value.name}<br />
                                                    {(contact.value.email !== '') &&
                                                        <a href={'mailto:'+contact.value.email}>
                                                            {contact.value.email}<br/>
                                                        </a>
                                                    }
                                                    {(contact.value.phone !== '') &&
                                                        <>
                                                            {contact.value.phone}<br/>
                                                        </>
                                                    }
                                                </p>

                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Kontakt;
