import React from 'react';
import OrganizationList from "../../components/OrganizationList";
import {Helmet} from "react-helmet";

const HostingOrganization = _ => {

    return <div className={'organizations'}>
        <Helmet>
            <title>{'Spielstätten – Freie Theater Hannover'}</title>
        </Helmet>
        <section className={'container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'fz-140 text-uppercase pb-5 mt-5 hyphenationhyphenation'}>Freie Spielstätten – Orte
                        der Theater- & Tanz-Szene</h1>
                    <p className={'big-lead'}>
                        Die Spielstätten der freien Szene Hannovers:
                    </p>
                </div>
            </div>
        </section>
        <OrganizationList organizationType={'HostingOrganization'}/>

        <div className={'container pt-5 pb-5 mb-5'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h2 className={'text-center pb-5 mb-5 text-uppercase'}>Deine Route zu uns</h2>
                    <svg viewBox="0 0 387.1 237.36">
                        <defs>
                            <symbol id="_01" viewBox="0 0 11.79 11.91">
                                <g>
                                    <text className="cls-18" transform="translate(3.94 8.51)">
                                        <tspan x="0" y="0">1</tspan>
                                    </text>
                                    <rect className="cls-9" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_02" viewBox="0 0 11.79 11.91">
                                <g>
                                    <text className="cls-18" transform="translate(3.85 8.51)">
                                        <tspan x="0" y="0">2</tspan>
                                    </text>
                                    <rect className="cls-9" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_03" viewBox="0 0 11.79 11.79">
                                <g>
                                    <rect className="cls-14" x=".75" y=".75" width="10.29" height="10.29"/>
                                    <text className="cls-18" transform="translate(3.8 8.64)">
                                        <tspan x="0" y="0">3</tspan>
                                    </text>
                                </g>
                            </symbol>
                            <symbol id="_04" viewBox="0 0 11.79 11.79">
                                <g>
                                    <text className="cls-18" transform="translate(3.85 8.15)">
                                        <tspan x="0" y="0">4</tspan>
                                    </text>
                                    <rect className="cls-13" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_05" viewBox="0 0 11.79 11.79">
                                <g>
                                    <text className="cls-18" transform="translate(4.02 8.15)">
                                        <tspan x="0" y="0">5</tspan>
                                    </text>
                                    <rect className="cls-13" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_06" viewBox="0 0 11.79 11.79">
                                <g>
                                    <text className="cls-18" transform="translate(3.94 8.21)">
                                        <tspan x="0" y="0">6</tspan>
                                    </text>
                                    <rect className="cls-13" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_07" viewBox="0 0 11.79 11.91">
                                <g>
                                    <rect className="cls-14" x=".75" y=".75" width="10.29" height="10.29"/>
                                    <text className="cls-18" transform="translate(3.8 8.5)">
                                        <tspan x="0" y="0">7</tspan>
                                    </text>
                                </g>
                            </symbol>
                            <symbol id="_08" viewBox="0 0 11.79 11.91">
                                <g>
                                    <text className="cls-18" transform="translate(3.85 8.51)">
                                        <tspan x="0" y="0">8</tspan>
                                    </text>
                                    <rect className="cls-9" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_09" viewBox="0 0 11.79 11.91">
                                <g>
                                    <text className="cls-18" transform="translate(3.8 8.5)">
                                        <tspan x="0" y="0">9</tspan>
                                    </text>
                                    <rect className="cls-14" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_10" viewBox="0 0 11.79 11.82">
                                <g>
                                    <text className="cls-18" transform="translate(1.89 8.42)">
                                        <tspan x="0" y="0">10</tspan>
                                    </text>
                                    <rect className="cls-12" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_11" viewBox="0 0 11.79 11.79">
                                <g>
                                    <text className="cls-18" transform="translate(2.14 8.21)">
                                        <tspan x="0" y="0">11</tspan>
                                    </text>
                                    <rect className="cls-13" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_16" viewBox="0 0 11.79 11.79">
                                <g>
                                    <text className="cls-18" transform="translate(1.66 8.15)">
                                        <tspan x="0" y="0">16</tspan>
                                    </text>
                                    <rect className="cls-13" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_17" viewBox="0 0 11.79 11.9">
                                <g>
                                    <text className="cls-18" transform="translate(1.66 8.5)">
                                        <tspan x="0" y="0">17</tspan>
                                    </text>
                                    <rect className="cls-12" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                            <symbol id="_18" viewBox="0 0 13.03 11.82">
                                <g>
                                    <text className="cls-18" transform="translate(1.71 8.42)">
                                        <tspan x="0" y="0">18</tspan>
                                    </text>
                                    <text/>
                                    <rect className="cls-9" x=".75" y=".75" width="10.29" height="10.29"/>
                                </g>
                            </symbol>
                        </defs>
                        <g id="Ebene_1">
                            <polyline className="cls-7"
                                      points="387.1 88.02 244.39 88.02 191.65 140.21 202.21 150.79 185.84 166.52 184.51 167.83 161.17 190.95 -1.58 190.95"/>
                            <polyline className="cls-8" points="140.44 .4 140.44 110.62 215.33 185.9 393.23 185.97"/>
                            <polyline className="cls-10"
                                      points="237.88 -1.98 237.88 83.41 180.89 140.41 212.17 171.69 211.6 241.73"/>
                            <polyline className="cls-11"
                                      points="204.26 106.29 190.11 120.23 165.96 120.21 119.39 166.76 119.4 184.74 119.4 190.76"/>
                            <polyline className="cls-11"
                                      points="201.2 98.73 186.63 112.12 162.47 112.1 113.14 161.41 80.57 161.01 -4.09 78.2"/>
                            <g>
                                <path className="cls-19" d="M252.54,55.71v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M255.93,55.71v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M261.48,55.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M265.63,55.71l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19" d="M272.02,55.71v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M275.41,55.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M280.04,55.71v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M287.01,55.71v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M289.11,55.71v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19"
                                      d="M296.71,55.71v-5.58h1.62c.93,0,1.65,.25,2.15,.74,.51,.49,.76,1.19,.76,2.1s-.25,1.56-.75,2.04c-.5,.47-1.22,.71-2.17,.71h-1.62Zm1.03-.95h.48c.42,0,.78-.05,1.07-.16,.29-.11,.52-.29,.68-.56s.24-.64,.24-1.11-.08-.82-.23-1.09c-.15-.27-.37-.46-.65-.58-.28-.11-.62-.17-1.01-.17h-.58v3.67Z"/>
                                <path className="cls-19"
                                      d="M302.1,55.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M306.72,55.71v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M313.7,55.71v-5.58h1.03v4.65h2.38v.93h-3.41Z"/>
                                <path className="cls-19" d="M317.73,55.71v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M321.67,55.81c-.59,0-1.08-.15-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36-.08-.1-.2-.19-.36-.28-.16-.09-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46s-.32-.16-.51-.16c-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54,.12,.21,.18,.46,.18,.75,0,.33-.08,.63-.24,.88-.16,.26-.39,.46-.68,.6s-.63,.22-1.02,.22Z"/>
                                <path className="cls-19" d="M325.7,55.71v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-2"
                                      d="M253.06,65.4c-.56,0-1.02-.15-1.38-.44-.36-.29-.58-.68-.66-1.17l.69-.17c.06,.37,.21,.66,.46,.85s.55,.29,.91,.29c.21,0,.39-.04,.56-.12s.3-.19,.39-.34c.1-.15,.14-.31,.14-.5,0-.2-.05-.36-.16-.49-.11-.13-.25-.24-.44-.34-.19-.1-.41-.19-.66-.29-.31-.12-.58-.25-.81-.38-.23-.14-.41-.31-.54-.5s-.19-.43-.19-.72,.07-.53,.21-.75c.14-.22,.33-.39,.58-.52,.25-.13,.54-.19,.87-.19,.35,0,.66,.08,.94,.24,.27,.16,.49,.4,.66,.71l-.56,.34c-.12-.22-.27-.39-.45-.5-.18-.11-.38-.17-.61-.17-.18,0-.34,.03-.48,.1-.14,.07-.25,.17-.33,.29-.08,.12-.12,.26-.12,.42,0,.17,.05,.32,.14,.44s.23,.23,.42,.32c.18,.09,.4,.18,.66,.28,.33,.12,.6,.26,.84,.4,.23,.14,.42,.32,.55,.52,.13,.2,.2,.45,.2,.74,0,.31-.08,.59-.23,.84-.15,.25-.37,.44-.64,.58-.27,.14-.59,.21-.94,.21Z"/>
                                <path className="cls-2"
                                      d="M257.48,65.37c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M261.74,65.37c-.36,0-.67-.09-.94-.27s-.48-.42-.63-.73c-.15-.31-.22-.66-.22-1.04,0-.42,.08-.78,.24-1.08,.16-.3,.38-.54,.65-.7,.27-.17,.57-.25,.9-.25,.25,0,.47,.05,.67,.15,.2,.1,.36,.23,.5,.4,.14,.17,.23,.36,.28,.56l-.19,.13v-2.81h.67v5.58h-.67v-1.18l.22,.07c-.07,.23-.17,.43-.31,.61-.14,.18-.31,.31-.5,.41-.2,.1-.42,.15-.67,.15Zm.11-.62c.24,0,.45-.06,.63-.18,.18-.12,.32-.29,.42-.5,.1-.21,.15-.46,.15-.74s-.05-.52-.15-.74c-.1-.21-.24-.38-.42-.5-.18-.12-.39-.18-.63-.18s-.45,.06-.64,.18c-.18,.12-.33,.29-.43,.5-.1,.21-.16,.46-.16,.74s.05,.52,.16,.74c.1,.21,.25,.38,.43,.5,.18,.12,.4,.18,.64,.18Z"/>
                                <path className="cls-2"
                                      d="M266.12,65.37c-.3,0-.56-.05-.78-.16s-.39-.25-.51-.44c-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19s.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7-.17-.17-.42-.25-.75-.25-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                <path className="cls-2"
                                      d="M269.15,65.31v-3.94h.68v.84l-.16,.06c.08-.2,.18-.38,.3-.52s.28-.25,.46-.33c.18-.08,.38-.12,.6-.12,.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.69-.22-.88-.15-.19-.37-.28-.67-.28-.22,0-.4,.05-.56,.15-.15,.1-.27,.26-.35,.48-.08,.22-.12,.49-.12,.83v1.97h-.68Z"/>
                                <path className="cls-2"
                                      d="M274.83,65.37c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5,.2,.13,.44,.2,.72,.2,.25,0,.45-.05,.6-.16,.15-.11,.22-.25,.22-.43,0-.15-.04-.27-.14-.35-.09-.08-.21-.15-.36-.21-.15-.05-.31-.1-.49-.14-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19,.25,.12,.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13-.21,.2-.21,.35c0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18,.12,.05,.28,.09,.46,.14,.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M276.79,61.89v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11-.15-.07-.27-.19-.36-.35-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M280.05,65.31v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M284.49,65.37c-.3,0-.56-.05-.78-.16s-.39-.25-.51-.44c-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19s.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7-.17-.17-.42-.25-.75-.25-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                <path className="cls-2"
                                      d="M290.27,65.37c-.26,0-.5-.05-.72-.14-.22-.09-.41-.22-.56-.38-.16-.16-.27-.34-.34-.54l.54-.25c.05,.13,.13,.26,.22,.37,.09,.11,.21,.2,.36,.27,.14,.07,.31,.1,.5,.1,.26,0,.45-.06,.59-.17,.14-.11,.2-.26,.2-.43,0-.14-.04-.25-.12-.33-.08-.08-.18-.15-.31-.2-.13-.05-.27-.1-.42-.14-.16-.04-.31-.08-.47-.13-.16-.05-.3-.11-.43-.19-.13-.08-.23-.18-.31-.31-.08-.13-.12-.29-.12-.49,0-.21,.04-.39,.12-.52,.08-.13,.18-.23,.31-.31,.13-.07,.26-.14,.4-.18,.14-.05,.27-.09,.4-.14,.13-.04,.23-.1,.31-.16s.12-.15,.12-.26-.05-.22-.14-.3c-.09-.08-.21-.14-.36-.18-.15-.04-.3-.06-.46-.06-.27,0-.48,.03-.65,.09-.17,.06-.3,.14-.39,.24-.09,.1-.16,.21-.2,.33-.04,.12-.06,.24-.07,.36s-.01,.22-.01,.31v3.67h-.7v-3.67c0-.25,.03-.48,.08-.72,.05-.23,.15-.44,.29-.63s.34-.34,.6-.45c.26-.11,.6-.17,1.02-.17,.33,0,.61,.05,.86,.14s.45,.22,.59,.4c.14,.17,.22,.38,.22,.63,0,.19-.04,.35-.12,.47-.08,.12-.18,.22-.31,.3-.12,.07-.26,.14-.4,.19s-.27,.1-.4,.15c-.12,.05-.23,.11-.31,.18s-.12,.17-.12,.29c0,.11,.04,.2,.12,.26s.18,.12,.31,.16,.27,.08,.43,.12c.16,.04,.31,.08,.47,.13,.15,.05,.3,.12,.42,.2,.13,.08,.23,.19,.31,.33,.08,.14,.12,.31,.12,.53s-.04,.39-.12,.54c-.08,.15-.19,.28-.32,.38-.14,.1-.29,.18-.46,.23-.17,.05-.35,.08-.54,.08Z"/>
                                <path className="cls-2"
                                      d="M294.26,65.37c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2" d="M296.01,67l2.86-7.26h.53l-2.86,7.26h-.52Z"/>
                                <path className="cls-2" d="M300.32,65.31v-5.58h.7v4.94h2.5v.63h-3.2Z"/>
                                <path className="cls-2"
                                      d="M304.15,60.65v-.92h.8v.92h-.8Zm.06,4.66v-3.94h.67v3.94h-.67Z"/>
                                <path className="cls-2"
                                      d="M307.33,65.37c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5,.2,.13,.44,.2,.72,.2,.25,0,.45-.05,.6-.16,.15-.11,.22-.25,.22-.43,0-.15-.04-.27-.14-.35-.09-.08-.21-.15-.36-.21-.15-.05-.31-.1-.49-.14-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19,.25,.12,.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13-.21,.2-.21,.35c0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18,.12,.05,.28,.09,.46,.14,.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M309.29,61.89v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11-.15-.07-.27-.19-.36-.35-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M314.14,65.37c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M316.8,65.31v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M322.19,65.31v-5.58h1.04l1.75,4.41h-.13l1.75-4.41h1.03v5.58h-.7v-4.76h.06s-1.73,4.29-1.73,4.29h-.69l-1.74-4.28h.06s0,4.75,0,4.75h-.7Z"/>
                                <path className="cls-2"
                                      d="M330.51,65.37c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M333.09,60.65v-.92h.8v.92h-.8Zm.06,4.66v-3.94h.67v3.94h-.67Z"/>
                                <path className="cls-2"
                                      d="M335.76,65.37c-.24,0-.43-.07-.58-.22-.15-.15-.22-.39-.22-.71v-4.7h.67v4.58c0,.15,.03,.27,.1,.34,.06,.07,.15,.1,.26,.1,.05,0,.1,0,.16-.02,.06-.01,.12-.03,.18-.05v.58c-.09,.04-.19,.06-.29,.08-.1,.02-.19,.03-.27,.03Z"/>
                                <path className="cls-2"
                                      d="M338.76,65.37c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M99.15,83.05c-.53,0-.99-.12-1.39-.36-.4-.24-.71-.58-.94-1.02-.23-.44-.34-.95-.34-1.53s.11-1.1,.34-1.53c.22-.43,.54-.77,.94-1.02s.87-.37,1.4-.37,1,.12,1.4,.36c.4,.24,.72,.58,.94,1.02,.22,.44,.34,.95,.34,1.54s-.11,1.09-.34,1.53c-.23,.44-.54,.78-.94,1.02s-.87,.36-1.4,.36Zm0-.99c.34,0,.63-.08,.88-.24,.24-.16,.43-.38,.56-.67,.13-.29,.2-.63,.2-1.02s-.07-.74-.2-1.02c-.13-.29-.32-.51-.56-.67-.24-.16-.53-.24-.88-.24s-.61,.08-.86,.23-.44,.38-.57,.66-.2,.63-.2,1.03,.07,.74,.2,1.02c.13,.29,.32,.51,.57,.66,.25,.15,.53,.23,.86,.23Zm2.37,1.31l-2.28-2.21,.61-.64,2.3,2.24-.62,.61Z"/>
                                <path className="cls-19"
                                      d="M105.03,83.05c-.7,0-1.24-.18-1.6-.54-.36-.36-.54-.89-.54-1.61v-3.56h1.03v3.44c0,.29,.03,.53,.09,.72,.06,.19,.17,.34,.34,.44,.16,.1,.39,.15,.68,.15s.51-.05,.68-.15c.16-.1,.28-.25,.34-.44,.06-.19,.1-.43,.1-.72v-3.45h1.03v3.52c0,.73-.18,1.27-.54,1.64-.36,.37-.9,.55-1.6,.55Z"/>
                                <path className="cls-19"
                                      d="M107.53,82.92l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M113.39,82.92v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M119.44,82.92v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19" d="M122.83,82.92v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M124.94,82.92v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M129.56,82.92v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-2"
                                      d="M70.9,92.52v-5.58h.7v5.58h-.7Zm.55-1.78v-.9l2.78-2.9h.94l-3.72,3.8Zm2.91,1.78l-1.9-3,.53-.49,2.19,3.49h-.82Z"/>
                                <path className="cls-2"
                                      d="M77.45,92.58c-.37,0-.7-.09-.99-.26-.29-.17-.52-.42-.68-.72-.17-.31-.25-.66-.25-1.06s.08-.75,.24-1.05c.16-.31,.39-.55,.68-.72,.29-.17,.62-.26,1-.26s.7,.09,.99,.26,.51,.41,.68,.72c.17,.31,.25,.66,.25,1.05s-.08,.75-.25,1.05c-.17,.31-.39,.55-.68,.72-.29,.18-.62,.26-.98,.26Zm0-.6c.25,0,.47-.06,.65-.18,.18-.12,.33-.29,.43-.5,.1-.22,.15-.47,.15-.76s-.05-.55-.15-.76c-.1-.22-.24-.38-.43-.5-.18-.12-.4-.18-.65-.18s-.48,.06-.66,.18c-.19,.12-.33,.29-.43,.5s-.15,.47-.15,.76,.05,.54,.15,.76c.1,.22,.25,.38,.43,.5,.19,.12,.41,.18,.66,.18Z"/>
                                <path className="cls-2"
                                      d="M80.33,94.15v-5.58h.67v1.18l-.22-.15c.07-.23,.17-.43,.31-.59s.3-.29,.5-.37,.42-.13,.67-.13c.36,0,.67,.09,.94,.27,.27,.18,.48,.42,.63,.73,.15,.31,.22,.65,.22,1.04,0,.41-.08,.77-.24,1.08-.16,.31-.38,.54-.65,.71-.27,.17-.57,.25-.9,.25-.25,0-.47-.05-.67-.14-.2-.09-.36-.22-.5-.38-.13-.16-.23-.34-.29-.54l.19-.18v2.81h-.67Zm1.82-2.18c.24,0,.45-.06,.64-.18,.18-.12,.33-.29,.43-.5,.1-.21,.16-.46,.16-.74s-.05-.52-.16-.74c-.1-.21-.25-.38-.43-.5-.18-.12-.4-.18-.64-.18s-.45,.06-.63,.18c-.18,.12-.32,.29-.42,.5s-.15,.46-.15,.74,.05,.52,.15,.74,.24,.38,.42,.5c.18,.12,.39,.18,.63,.18Z"/>
                                <path className="cls-2"
                                      d="M86.69,92.58c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M89.35,92.52v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M92.6,92.52v-3.94h.68v.84l-.16,.06c.08-.2,.18-.38,.3-.52s.28-.25,.46-.33c.18-.08,.38-.12,.6-.12,.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.69-.22-.88-.15-.19-.37-.28-.67-.28-.22,0-.4,.05-.56,.15-.15,.1-.27,.26-.35,.48-.08,.22-.12,.49-.12,.83v1.97h-.68Z"/>
                                <path className="cls-2"
                                      d="M96.91,87.86v-.92h.8v.92h-.8Zm.06,4.66v-3.94h.67v3.94h-.67Z"/>
                                <path className="cls-2"
                                      d="M98.79,92.52v-5.58h.68v5.58h-.68Zm2.69,0l-1.31-2.03-.78,.83v-.87l1.82-1.87h.87l-1.42,1.46,1.62,2.49h-.81Z"/>
                                <path className="cls-2"
                                      d="M104.3,92.58c-.28,0-.53-.06-.74-.17s-.37-.28-.48-.51c-.11-.22-.16-.5-.16-.83v-2.5h.67v2.26c0,.26,.03,.48,.1,.65s.17,.3,.3,.38c.13,.08,.3,.12,.5,.12,.22,0,.4-.05,.56-.16,.15-.1,.27-.26,.35-.48,.08-.21,.12-.49,.12-.82v-1.96h.67v3.94h-.67v-.84h.17c-.11,.29-.29,.52-.54,.67s-.53,.23-.85,.23Z"/>
                                <path className="cls-2"
                                      d="M108.65,92.58c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5,.2,.13,.44,.2,.72,.2,.25,0,.45-.05,.6-.16,.15-.11,.22-.25,.22-.43,0-.15-.04-.27-.14-.35-.09-.08-.21-.15-.36-.21-.15-.05-.31-.1-.49-.14-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19,.25,.12,.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13-.21,.2-.21,.35c0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18,.12,.05,.28,.09,.46,.14,.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M112.41,92.58c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5,.2,.13,.44,.2,.72,.2,.25,0,.45-.05,.6-.16,.15-.11,.22-.25,.22-.43,0-.15-.04-.27-.14-.35-.09-.08-.21-.15-.36-.21-.15-.05-.31-.1-.49-.14-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19,.25,.12,.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13-.21,.2-.21,.35c0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18,.12,.05,.28,.09,.46,.14,.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M114.37,89.1v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11-.15-.07-.27-.19-.36-.35-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M117.63,92.52v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M122.07,92.58c-.3,0-.56-.05-.78-.16s-.39-.25-.51-.44c-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19s.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7-.17-.17-.42-.25-.75-.25-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                <path className="cls-2"
                                      d="M127.85,92.58c-.26,0-.5-.05-.72-.14-.22-.09-.41-.22-.56-.38-.16-.16-.27-.34-.34-.54l.54-.25c.05,.13,.13,.26,.22,.37,.09,.11,.21,.2,.36,.27,.14,.07,.31,.1,.5,.1,.26,0,.45-.06,.59-.17,.14-.11,.2-.26,.2-.43,0-.14-.04-.25-.12-.33-.08-.08-.18-.15-.31-.2-.13-.05-.27-.1-.42-.14-.16-.04-.31-.08-.47-.13-.16-.05-.3-.11-.43-.19-.13-.08-.23-.18-.31-.31-.08-.13-.12-.29-.12-.49,0-.21,.04-.39,.12-.52,.08-.13,.18-.23,.31-.31,.13-.07,.26-.14,.4-.18,.14-.05,.27-.09,.4-.14,.13-.04,.23-.1,.31-.16s.12-.15,.12-.26-.05-.22-.14-.3c-.09-.08-.21-.14-.36-.18-.15-.04-.3-.06-.46-.06-.27,0-.48,.03-.65,.09-.17,.06-.3,.14-.39,.24-.09,.1-.16,.21-.2,.33-.04,.12-.06,.24-.07,.36s-.01,.22-.01,.31v3.67h-.7v-3.67c0-.25,.03-.48,.08-.72,.05-.23,.15-.44,.29-.63s.34-.34,.6-.45c.26-.11,.6-.17,1.02-.17,.33,0,.61,.05,.86,.14s.45,.22,.59,.4c.14,.17,.22,.38,.22,.63,0,.19-.04,.35-.12,.47-.08,.12-.18,.22-.31,.3-.12,.07-.26,.14-.4,.19s-.27,.1-.4,.15c-.12,.05-.23,.11-.31,.18s-.12,.17-.12,.29c0,.11,.04,.2,.12,.26s.18,.12,.31,.16,.27,.08,.43,.12c.16,.04,.31,.08,.47,.13,.15,.05,.3,.12,.42,.2,.13,.08,.23,.19,.31,.33,.08,.14,.12,.31,.12,.53s-.04,.39-.12,.54c-.08,.15-.19,.28-.32,.38-.14,.1-.29,.18-.46,.23-.17,.05-.35,.08-.54,.08Z"/>
                                <path className="cls-2"
                                      d="M131.84,92.58c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64s-.12-.5-.12-.77,.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46s.49-.17,.78-.17,.57,.05,.79,.16,.4,.25,.54,.43c.14,.18,.25,.38,.32,.61s.1,.46,.1,.7c0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9s.49,.32,.85,.32c.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35-.2-.19-.34-.25c-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                            </g>
                            <g>
                                <path className="cls-19" d="M58.45,207.52v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M60.62,207.52l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M66.48,207.52v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19"
                                      d="M71.68,207.52v-.85l3.24-4.13,.15,.33h-3.21v-.93h4.1v.85l-3.14,4.01-.14-.21h3.4v.93h-4.39Z"/>
                                <path className="cls-19"
                                      d="M76.83,207.52v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M81.9,207.52l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M89.65,207.65c-.7,0-1.24-.18-1.6-.54-.36-.36-.54-.89-.54-1.61v-3.56h1.03v3.44c0,.29,.03,.53,.09,.72,.06,.2,.17,.34,.34,.44,.16,.1,.39,.15,.68,.15s.51-.05,.68-.15c.16-.1,.28-.25,.34-.44,.06-.19,.1-.43,.1-.72v-3.45h1.03v3.52c0,.73-.18,1.27-.54,1.64-.36,.37-.9,.55-1.6,.55Z"/>
                                <path className="cls-19"
                                      d="M94.66,207.63c-.59,0-1.08-.16-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36s-.2-.2-.36-.28c-.16-.08-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46-.15-.1-.32-.16-.51-.16-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54s.18,.46,.18,.75c0,.33-.08,.62-.24,.88-.16,.26-.39,.46-.68,.6-.29,.14-.63,.22-1.02,.22Z"/>
                                <path className="cls-19" d="M99.49,207.52v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M101.6,207.52v-5.58h1.51l1.47,4.05h-.18l1.46-4.05h1.51v5.58h-1.03v-4.37l.1,.02-1.45,3.86h-1l-1.45-3.86,.09-.02v4.37h-1.03Z"/>
                                <path className="cls-19"
                                      d="M42.07,217.12l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M47.94,217.12v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M53.48,217.12v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M58.36,217.12v-5.58h1.96c.55,0,.99,.12,1.3,.37s.47,.59,.47,1.04c0,.26-.05,.48-.16,.68-.11,.2-.26,.36-.45,.47,.31,.12,.54,.29,.69,.52,.15,.23,.22,.52,.22,.86,0,.51-.16,.91-.48,1.2-.32,.29-.77,.43-1.34,.43h-2.22Zm1.03-3.34h.98c.21,0,.38-.06,.51-.18,.13-.12,.19-.28,.19-.49,0-.2-.06-.35-.19-.46s-.31-.16-.55-.16h-.93v1.29Zm0,2.38h1.1c.58,0,.87-.24,.87-.73,0-.23-.07-.42-.21-.54s-.34-.19-.6-.19h-1.16v1.46Z"/>
                                <path className="cls-19"
                                      d="M63.25,217.12v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M67.87,217.12v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M75.01,217.25c-.51,0-.96-.12-1.35-.37-.39-.25-.7-.59-.92-1.02-.22-.44-.34-.95-.34-1.53s.11-1.09,.34-1.52c.22-.43,.54-.78,.94-1.02s.87-.37,1.4-.37c.59,0,1.1,.15,1.53,.44,.43,.3,.74,.7,.92,1.22l-.98,.36c-.12-.33-.3-.58-.56-.76-.25-.18-.56-.27-.92-.27-.33,0-.62,.08-.87,.23-.25,.15-.44,.37-.57,.66-.13,.29-.2,.63-.2,1.03s.07,.74,.2,1.03,.32,.51,.57,.66,.54,.23,.87,.23c.17,0,.35-.02,.53-.07,.18-.05,.35-.13,.5-.25,.15-.12,.28-.29,.37-.5,.09-.21,.14-.49,.14-.82v-.32l.23,.72h-1.6v-.93h2.29v3.03h-.91l-.14-.9,.18,.14c-.1,.2-.23,.36-.39,.5-.17,.13-.35,.23-.56,.3-.21,.07-.44,.1-.68,.1Z"/>
                                <path className="cls-19"
                                      d="M80.02,217.12l-2.23-5.58h1.14l1.66,4.3h-.33l1.62-4.3h1.14l-2.22,5.58h-.78Z"/>
                                <path className="cls-19" d="M83.62,217.12v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M85.73,217.12v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M90.35,217.12v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M96.4,217.12v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M99.79,217.12v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19" d="M104.41,217.12v-5.58h1.03v4.65h2.38v.93h-3.41Z"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M243.22,30.6c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="237.99" cy="30.44" r="2.33"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M261.19,77.05c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="255.96" cy="76.89" r="2.33"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M145.68,88.18c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="140.44" cy="88.02" r="2.33"/>
                            </g>
                            <rect className="cls-4" x="180.89" y="105.1" width="25.85" height="3.92"/>
                            <rect className="cls-4" x="181.21" y="93.2" width="24.2" height="13.7"/>
                            <g>
                                <path className="cls-19" d="M71.37,122.13v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M74.77,122.13v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M80.31,122.13v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M84.46,122.13l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19" d="M90.85,122.13v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M94.24,122.13v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M98.87,122.13v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M105.37,122.13l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M111.24,122.13v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19"
                                      d="M59.37,131.73v-5.58h1.62c.93,0,1.65,.25,2.15,.74,.51,.49,.76,1.19,.76,2.1s-.25,1.56-.75,2.04c-.5,.47-1.22,.71-2.17,.71h-1.62Zm1.03-.95h.48c.42,0,.78-.05,1.07-.16,.29-.11,.52-.29,.68-.56s.24-.64,.24-1.11-.08-.82-.23-1.09c-.15-.27-.37-.46-.65-.58-.28-.11-.62-.17-1.01-.17h-.58v3.67Z"/>
                                <path className="cls-19"
                                      d="M64.76,131.73v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M69.38,131.73v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M78.74,131.86c-.51,0-.96-.12-1.35-.37-.39-.25-.7-.59-.92-1.02-.22-.44-.34-.95-.34-1.53s.11-1.09,.34-1.52c.22-.43,.54-.77,.94-1.02s.87-.37,1.4-.37c.59,0,1.1,.15,1.53,.44,.43,.3,.74,.7,.92,1.22l-.98,.36c-.12-.33-.3-.58-.56-.76-.25-.18-.56-.27-.92-.27-.33,0-.62,.08-.87,.23s-.44,.37-.57,.66c-.13,.29-.2,.63-.2,1.03s.07,.74,.2,1.03c.13,.29,.32,.51,.57,.66,.25,.15,.54,.23,.87,.23,.17,0,.35-.02,.53-.07,.18-.05,.35-.13,.5-.25,.15-.12,.28-.29,.37-.5,.09-.21,.14-.49,.14-.82v-.32l.23,.72h-1.6v-.93h2.29v3.03h-.91l-.14-.9,.18,.14c-.1,.2-.23,.36-.39,.5s-.35,.23-.56,.3c-.21,.07-.44,.1-.68,.1Z"/>
                                <path className="cls-19" d="M82.24,131.73v-5.58h1.03v4.65h2.38v.93h-3.41Z"/>
                                <path className="cls-19"
                                      d="M88.53,131.86c-.53,0-.99-.12-1.4-.36s-.72-.58-.94-1.02c-.22-.44-.34-.95-.34-1.53s.11-1.1,.34-1.54c.22-.44,.54-.78,.94-1.02s.87-.36,1.4-.36,1,.12,1.4,.36c.4,.24,.72,.58,.94,1.02,.22,.44,.34,.95,.34,1.54s-.11,1.09-.34,1.53c-.23,.44-.54,.78-.94,1.02s-.87,.36-1.4,.36Zm0-.99c.34,0,.63-.08,.88-.24,.24-.16,.43-.38,.56-.67,.13-.29,.2-.63,.2-1.02s-.07-.74-.2-1.02c-.13-.29-.32-.51-.56-.67-.24-.16-.53-.24-.88-.24s-.61,.08-.86,.23-.44,.38-.57,.66-.2,.63-.2,1.03,.07,.74,.2,1.02c.13,.29,.32,.51,.57,.66,.25,.15,.53,.23,.86,.23Z"/>
                                <path className="cls-19"
                                      d="M94.5,131.86c-.53,0-.99-.12-1.4-.37-.4-.25-.72-.59-.94-1.02-.22-.44-.34-.95-.34-1.53s.11-1.09,.34-1.52c.22-.43,.54-.77,.94-1.02s.87-.37,1.4-.37c.59,0,1.1,.15,1.53,.44,.43,.3,.74,.7,.92,1.22l-.98,.36c-.12-.33-.3-.58-.56-.76-.25-.18-.56-.27-.92-.27-.33,0-.62,.08-.87,.23s-.44,.37-.57,.66c-.13,.29-.2,.63-.2,1.03s.07,.74,.2,1.03c.13,.29,.32,.51,.57,.66,.25,.15,.54,.23,.87,.23,.36,0,.67-.09,.92-.27,.25-.18,.44-.43,.56-.76l.98,.35c-.19,.52-.5,.93-.92,1.22-.43,.3-.94,.45-1.53,.45Z"/>
                                <path className="cls-19"
                                      d="M97.72,131.73v-5.58h1.03v5.58h-1.03Zm.82-1.68v-1.31l2.45-2.58h1.39l-3.84,3.9Zm2.63,1.68l-1.76-2.81,.77-.72,2.22,3.53h-1.22Z"/>
                                <path className="cls-19"
                                      d="M104.74,131.84c-.59,0-1.08-.15-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36-.08-.1-.2-.19-.36-.28-.16-.09-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46s-.32-.16-.51-.16c-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54,.12,.21,.18,.46,.18,.75,0,.33-.08,.63-.24,.88-.16,.26-.39,.46-.68,.6s-.63,.22-1.02,.22Z"/>
                                <path className="cls-19"
                                      d="M107.5,131.73v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M112.12,131.73v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-2"
                                      d="M75.26,141.43c-.5,0-.94-.12-1.32-.36-.38-.24-.68-.58-.89-1.01-.21-.43-.32-.94-.32-1.52s.11-1.08,.32-1.51c.22-.43,.52-.77,.9-1.01,.39-.24,.84-.36,1.35-.36,.56,0,1.05,.14,1.46,.42,.41,.28,.71,.66,.89,1.15l-.66,.25c-.14-.36-.35-.64-.64-.84-.29-.2-.64-.3-1.05-.3-.38,0-.71,.09-.99,.27-.28,.18-.5,.44-.65,.76s-.23,.72-.23,1.17,.08,.85,.23,1.18c.15,.33,.37,.59,.65,.77,.28,.18,.61,.27,.99,.27,.19,0,.39-.03,.59-.08,.2-.05,.39-.15,.56-.28,.17-.13,.31-.32,.41-.55,.1-.23,.16-.53,.16-.88v-.41l.16,.48h-1.57v-.63h2.03v2.93h-.62l-.1-1.09,.12,.09c-.1,.26-.23,.46-.42,.62-.18,.16-.39,.28-.63,.36s-.48,.12-.74,.12Z"/>
                                <path className="cls-2"
                                      d="M80.48,141.4c-.37,0-.7-.09-.99-.26-.29-.17-.52-.42-.68-.72-.17-.31-.25-.66-.25-1.06s.08-.75,.24-1.05c.16-.31,.39-.55,.68-.72,.29-.17,.62-.26,1-.26s.7,.09,.99,.26c.29,.17,.51,.41,.68,.72,.17,.31,.25,.66,.25,1.05s-.08,.75-.25,1.05c-.17,.31-.39,.55-.68,.72-.29,.18-.62,.26-.98,.26Zm0-.6c.25,0,.47-.06,.65-.18,.18-.12,.33-.29,.43-.5,.1-.22,.15-.47,.15-.76s-.05-.55-.15-.76c-.1-.22-.24-.38-.43-.5-.18-.12-.4-.18-.65-.18s-.48,.06-.66,.18c-.19,.12-.33,.29-.43,.5-.1,.21-.15,.47-.15,.76s.05,.54,.15,.76,.25,.38,.43,.5c.19,.12,.41,.18,.66,.18Z"/>
                                <path className="cls-2"
                                      d="M85.03,141.4c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66s.33-.34,.56-.46c.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43s.25,.38,.32,.61c.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15s0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25s-.3-.09-.48-.09c-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M87.15,137.92v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11s-.27-.19-.36-.35c-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M90.41,141.33v-5.58h.68v2.47l-.17,.06c.08-.2,.18-.38,.31-.52,.13-.14,.29-.25,.47-.33s.38-.12,.59-.12c.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.69-.22-.88-.15-.19-.37-.28-.67-.28-.33,0-.58,.12-.76,.35-.18,.23-.27,.6-.27,1.11v1.97h-.68Z"/>
                                <path className="cls-2"
                                      d="M96.45,141.4c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66s.33-.34,.56-.46c.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43s.25,.38,.32,.61c.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15s0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25s-.3-.09-.48-.09c-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M99.11,142.97v-5.58h.67v1.18l-.22-.15c.07-.23,.17-.42,.31-.59,.14-.16,.3-.29,.5-.37,.2-.08,.42-.13,.67-.13,.36,0,.67,.09,.94,.27,.27,.18,.48,.42,.63,.73s.22,.65,.22,1.04c0,.41-.08,.77-.24,1.08-.16,.31-.38,.54-.65,.71s-.57,.25-.9,.25c-.25,0-.47-.05-.67-.14-.2-.09-.36-.22-.5-.38-.13-.16-.23-.34-.29-.54l.19-.18v2.81h-.67Zm1.82-2.18c.24,0,.45-.06,.64-.18,.18-.12,.33-.29,.43-.5,.1-.21,.16-.46,.16-.74s-.05-.52-.16-.74c-.1-.21-.25-.38-.43-.5-.18-.12-.4-.18-.64-.18s-.45,.06-.63,.18c-.18,.12-.32,.29-.42,.5-.1,.21-.15,.46-.15,.74s.05,.52,.15,.74c.1,.21,.24,.38,.42,.5,.18,.12,.39,.18,.63,.18Z"/>
                                <path className="cls-2"
                                      d="M104.6,141.4c-.24,0-.43-.08-.58-.22-.15-.15-.22-.39-.22-.71v-4.7h.67v4.58c0,.16,.03,.27,.1,.34,.06,.07,.15,.1,.26,.1,.05,0,.1,0,.16-.02,.06,0,.12-.03,.18-.05v.58c-.09,.04-.19,.07-.29,.08-.1,.02-.19,.03-.27,.03Z"/>
                                <path className="cls-2"
                                      d="M107.21,141.4c-.3,0-.56-.05-.78-.16-.22-.1-.39-.25-.51-.44-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19,.18-.04,.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7s-.42-.25-.75-.25c-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                <path className="cls-2"
                                      d="M109.75,137.92v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11s-.27-.19-.36-.35c-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M112.73,141.33v-.48l2.5-3.07,.03,.15h-2.42v-.54h3.04v.51l-2.48,3.09-.02-.21h2.49v.55h-3.14Z"/>
                                <path className="cls-2"
                                      d="M80.42,152.57c-.15-.22-.29-.47-.41-.76-.12-.29-.22-.6-.3-.92-.08-.32-.14-.65-.18-.98s-.06-.64-.06-.93c0-.44,.04-.88,.11-1.32,.07-.44,.18-.86,.32-1.25s.32-.74,.52-1.05h.62c-.23,.33-.43,.69-.58,1.1-.15,.41-.26,.82-.34,1.25-.07,.43-.11,.85-.11,1.26,0,.3,.02,.61,.06,.94,.04,.33,.11,.65,.2,.97,.09,.32,.2,.63,.32,.92,.13,.29,.27,.55,.44,.77h-.62Z"/>
                                <path className="cls-2"
                                      d="M83.99,151.03c-.5,0-.94-.12-1.32-.36-.38-.24-.68-.58-.89-1.01-.21-.43-.32-.94-.32-1.52s.11-1.08,.32-1.51c.22-.43,.52-.77,.9-1.01,.39-.24,.84-.36,1.35-.36,.56,0,1.05,.14,1.46,.42,.41,.28,.71,.66,.89,1.15l-.66,.25c-.14-.36-.35-.64-.64-.84-.29-.2-.64-.3-1.05-.3-.38,0-.71,.09-.99,.27-.28,.18-.5,.44-.65,.76-.15,.33-.23,.72-.23,1.17s.08,.84,.23,1.18,.37,.59,.65,.77c.28,.18,.61,.27,.99,.27,.19,0,.39-.03,.59-.08,.2-.05,.39-.15,.56-.28,.17-.13,.31-.32,.41-.55,.1-.23,.16-.53,.16-.88v-.41l.16,.48h-1.57v-.63h2.03v2.93h-.62l-.1-1.09,.12,.09c-.1,.26-.23,.46-.42,.62-.18,.16-.39,.28-.63,.36s-.48,.12-.74,.12Z"/>
                                <path className="cls-2"
                                      d="M88.27,151c-.24,0-.43-.07-.58-.22s-.22-.39-.22-.71v-4.7h.67v4.58c0,.15,.03,.27,.1,.34,.06,.07,.15,.1,.26,.1,.05,0,.1,0,.16-.02s.12-.03,.18-.05v.58c-.09,.04-.19,.06-.29,.08-.1,.02-.19,.03-.27,.03Z"/>
                                <path className="cls-2"
                                      d="M91.33,151c-.37,0-.7-.09-.99-.26-.29-.17-.52-.42-.68-.72-.17-.31-.25-.66-.25-1.06s.08-.75,.24-1.05c.16-.31,.39-.55,.68-.72,.29-.17,.62-.26,1-.26s.7,.09,.99,.26c.29,.17,.51,.41,.68,.72,.17,.31,.25,.66,.25,1.05s-.08,.75-.25,1.05c-.17,.31-.39,.55-.68,.72s-.62,.26-.98,.26Zm0-.6c.25,0,.47-.06,.65-.18,.18-.12,.33-.29,.43-.5,.1-.22,.15-.47,.15-.76s-.05-.55-.15-.76c-.1-.22-.24-.38-.43-.5-.18-.12-.4-.18-.65-.18s-.48,.06-.66,.18c-.19,.12-.33,.29-.43,.5-.1,.21-.15,.47-.15,.76s.05,.54,.15,.76c.1,.22,.25,.38,.43,.5,.19,.12,.41,.18,.66,.18Z"/>
                                <path className="cls-2"
                                      d="M95.85,151c-.29,0-.55-.05-.78-.16-.23-.11-.42-.25-.57-.44-.16-.19-.27-.41-.35-.65s-.12-.51-.12-.78,.04-.53,.12-.78,.2-.46,.35-.65c.15-.19,.34-.34,.57-.44,.23-.11,.49-.16,.78-.16,.37,0,.7,.09,.98,.27,.28,.18,.49,.46,.62,.83l-.6,.23c-.07-.22-.19-.41-.36-.54-.17-.14-.38-.21-.64-.21s-.48,.07-.65,.21c-.17,.14-.3,.32-.38,.54-.08,.22-.12,.45-.12,.7s.04,.49,.12,.71c.08,.22,.21,.4,.38,.54,.17,.14,.39,.2,.65,.2s.47-.07,.64-.2c.17-.14,.29-.32,.36-.55l.6,.23c-.13,.37-.34,.65-.62,.84-.28,.18-.61,.28-.98,.28Z"/>
                                <path className="cls-2"
                                      d="M98.36,150.93v-5.58h.68v5.58h-.68Zm2.69,0l-1.31-2.03-.78,.83v-.87l1.82-1.87h.87l-1.42,1.46,1.62,2.49h-.81Z"/>
                                <path className="cls-2"
                                      d="M103.92,151c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5s.44,.2,.72,.2c.25,0,.45-.05,.6-.16s.22-.25,.22-.43c0-.15-.04-.27-.14-.35s-.21-.15-.36-.21-.31-.1-.49-.14c-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19s.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13c-.14,.08-.21,.2-.21,.35,0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18s.28,.09,.46,.14c.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M108.04,151c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46,.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43,.14,.18,.25,.38,.32,.61,.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.08,.33-.19,.48-.36l.47,.42c-.14,.16-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.08,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42s-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M112.36,151c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46,.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43,.14,.18,.25,.38,.32,.61,.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.08,.33-.19,.48-.36l.47,.42c-.14,.16-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.08,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42s-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M115.07,152.57h-.62c.17-.22,.32-.48,.44-.77,.12-.29,.23-.6,.32-.92,.09-.32,.15-.65,.2-.97s.06-.64,.06-.94c0-.41-.04-.83-.11-1.26-.07-.43-.18-.85-.34-1.25s-.35-.77-.58-1.1h.62c.21,.3,.39,.65,.53,1.05s.24,.81,.32,1.25c.07,.44,.11,.88,.11,1.32,0,.29-.02,.6-.06,.93s-.1,.65-.18,.98c-.08,.32-.18,.63-.3,.92-.12,.29-.25,.54-.41,.76Z"/>
                            </g>
                            <use width="11.79" height="11.9" transform="translate(101.39 167.66)" xlinkHref="#_17"/>
                            <use width="11.79" height="11.79" transform="translate(106.68 96.65)" xlinkHref="#_06"/>
                            <use width="11.79" height="11.79" transform="translate(120.47 96.65)" xlinkHref="#_11"/>
                            <g>
                                <path className="cls-19"
                                      d="M222.42,209.52v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19" d="M227.04,209.52v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M230.98,209.63c-.59,0-1.08-.16-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36s-.2-.2-.36-.28c-.16-.08-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46-.15-.1-.32-.16-.51-.16-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54s.18,.46,.18,.75c0,.33-.08,.62-.24,.88-.16,.26-.39,.46-.68,.6-.29,.14-.63,.22-1.02,.22Z"/>
                                <path className="cls-19"
                                      d="M233.74,209.52v-5.58h3.73v.93h-2.7v1.33h2.34v.92h-2.34v2.4h-1.03Z"/>
                                <path className="cls-19"
                                      d="M237.07,209.52l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M242.93,209.52v-5.58h1.96c.55,0,.99,.12,1.3,.37s.47,.59,.47,1.04c0,.26-.05,.48-.16,.68-.11,.2-.26,.36-.45,.47,.31,.12,.54,.29,.69,.52,.15,.23,.22,.52,.22,.86,0,.51-.16,.91-.48,1.2-.32,.29-.77,.43-1.34,.43h-2.22Zm1.03-3.34h.98c.21,0,.38-.06,.51-.18,.13-.12,.19-.28,.19-.49,0-.2-.06-.35-.19-.46s-.31-.16-.55-.16h-.93v1.29Zm0,2.38h1.1c.58,0,.87-.24,.87-.73,0-.23-.07-.42-.21-.54s-.34-.19-.6-.19h-1.16v1.46Z"/>
                                <path className="cls-19"
                                      d="M247.82,209.52v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M252.7,209.52v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M254.81,209.52v-5.58h1.03v5.58h-1.03Zm.82-1.68v-1.31l2.45-2.58h1.39l-3.84,3.9Zm2.63,1.68l-1.76-2.81,.77-.72,2.22,3.53h-1.22Z"/>
                                <path className="cls-2"
                                      d="M224.22,219.21c-.56,0-1.02-.15-1.38-.44s-.58-.68-.66-1.17l.69-.17c.06,.37,.21,.66,.46,.85,.25,.2,.55,.29,.91,.29,.21,0,.39-.04,.56-.12s.3-.19,.39-.34c.1-.15,.14-.31,.14-.5,0-.2-.05-.36-.16-.49-.11-.13-.25-.24-.44-.34-.19-.1-.41-.19-.66-.29-.31-.12-.58-.25-.81-.38-.23-.14-.41-.3-.54-.5-.13-.19-.19-.43-.19-.72s.07-.53,.21-.75c.14-.22,.33-.39,.58-.52,.25-.13,.54-.19,.87-.19,.35,0,.66,.08,.94,.24,.27,.16,.49,.4,.66,.71l-.56,.34c-.12-.22-.27-.38-.45-.5-.18-.11-.38-.17-.61-.17-.18,0-.34,.04-.48,.1-.14,.07-.25,.17-.33,.29-.08,.12-.12,.26-.12,.42,0,.17,.05,.32,.14,.44s.23,.23,.42,.32c.18,.09,.4,.18,.66,.28,.33,.12,.6,.26,.84,.4,.23,.14,.42,.32,.55,.52,.13,.2,.2,.45,.2,.74,0,.31-.08,.59-.23,.84-.15,.25-.37,.44-.64,.58-.27,.14-.59,.21-.94,.21Z"/>
                                <path className="cls-2"
                                      d="M228.61,219.18c-.29,0-.55-.05-.78-.16-.23-.11-.42-.25-.57-.44-.16-.19-.27-.41-.35-.65s-.12-.51-.12-.78,.04-.53,.12-.78,.2-.46,.35-.65c.15-.19,.34-.34,.57-.44,.23-.11,.49-.16,.78-.16,.37,0,.7,.09,.98,.27,.28,.18,.49,.46,.62,.83l-.6,.23c-.07-.22-.19-.41-.36-.54-.17-.14-.38-.21-.64-.21s-.48,.07-.65,.21c-.17,.14-.3,.32-.38,.54-.08,.22-.12,.45-.12,.7s.04,.49,.12,.71c.08,.22,.21,.4,.38,.54,.17,.14,.39,.2,.65,.2s.47-.07,.64-.2c.17-.14,.29-.32,.36-.55l.6,.23c-.13,.37-.34,.65-.62,.84-.28,.18-.61,.28-.98,.28Z"/>
                                <path className="cls-2"
                                      d="M231.11,219.12v-5.58h.68v2.47l-.17,.06c.08-.2,.18-.38,.31-.52,.13-.14,.29-.25,.47-.33s.38-.12,.59-.12c.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.69-.22-.88-.15-.19-.37-.28-.67-.28-.33,0-.58,.12-.76,.35-.18,.23-.27,.6-.27,1.11v1.97h-.68Z"/>
                                <path className="cls-2"
                                      d="M236.28,219.18c-.24,0-.43-.08-.58-.22-.15-.15-.22-.39-.22-.71v-4.7h.67v4.58c0,.16,.03,.27,.1,.34,.06,.07,.15,.1,.26,.1,.05,0,.1,0,.16-.02,.06,0,.12-.03,.18-.05v.58c-.09,.04-.19,.07-.29,.08-.1,.02-.19,.03-.27,.03Z"/>
                                <path className="cls-2"
                                      d="M238.9,219.18c-.3,0-.56-.05-.78-.16-.22-.1-.39-.25-.51-.44-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19,.18-.04,.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7s-.42-.25-.75-.25c-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Zm-2.11-4.66v-.92h.8v.92h-.8Zm1.48,0v-.92h.8v.92h-.8Z"/>
                                <path className="cls-2"
                                      d="M243.52,220.82c-.57,0-1.01-.1-1.34-.31-.33-.21-.5-.5-.5-.86,0-.03,0-.06,0-.1,0-.04,0-.08,.02-.12l.98-.71,.23,.16-.56,.7s0,.04,0,.06v.05c0,.21,.11,.37,.34,.48,.22,.1,.5,.16,.83,.16,.29,0,.54-.04,.73-.12,.19-.08,.29-.22,.29-.4,0-.11-.03-.19-.09-.26-.06-.07-.15-.12-.26-.16-.12-.04-.25-.07-.4-.1-.15-.03-.33-.05-.52-.08-.24-.04-.47-.09-.7-.16s-.41-.17-.56-.3c-.15-.13-.22-.31-.22-.53,0-.19,.07-.36,.2-.51,.13-.16,.3-.28,.52-.38l.43,.28c-.18,.04-.31,.11-.38,.2-.07,.09-.11,.19-.11,.32,0,.1,.04,.18,.11,.25,.07,.06,.18,.11,.31,.15,.14,.04,.3,.07,.49,.09,.26,.04,.5,.08,.72,.12s.42,.1,.6,.18c.17,.08,.31,.18,.4,.31s.14,.3,.14,.51c0,.36-.15,.64-.45,.84-.3,.19-.71,.29-1.23,.29Zm-.1-2.94c-.3,0-.57-.06-.81-.18-.23-.12-.42-.28-.55-.49s-.2-.45-.2-.71,.07-.52,.2-.73c.14-.21,.32-.38,.55-.49,.23-.12,.49-.18,.77-.18s.53,.06,.76,.18c.23,.12,.41,.28,.55,.5,.14,.21,.21,.46,.21,.75,0,.27-.06,.51-.19,.71-.13,.2-.3,.36-.53,.47-.22,.11-.48,.17-.78,.17Zm-.02-.55c.27,0,.47-.08,.62-.24,.15-.16,.22-.36,.22-.6,0-.25-.08-.45-.23-.61s-.36-.23-.61-.23-.46,.08-.62,.24c-.16,.16-.24,.36-.24,.6s.08,.44,.23,.6c.16,.16,.37,.24,.63,.24Zm1.26-1.42l-.49-.33,.83-.69,.34,.42-.69,.59Z"/>
                                <path className="cls-2"
                                      d="M247.63,219.18c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66s.33-.34,.56-.46c.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43s.25,.38,.32,.61c.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15s0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25s-.3-.09-.48-.09c-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                <path className="cls-2"
                                      d="M250.29,219.12v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04s.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08s-.17-.03-.25-.03c-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.08-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M254.73,219.18c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5,.2,.13,.44,.2,.72,.2,.25,0,.45-.05,.6-.16,.15-.11,.22-.25,.22-.43,0-.15-.04-.27-.14-.35-.09-.08-.21-.15-.36-.21-.15-.05-.31-.1-.49-.14-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19,.25,.12,.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13c-.14,.09-.21,.2-.21,.35,0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18,.12,.05,.28,.09,.46,.14,.17,.04,.33,.08,.5,.14s.33,.12,.47,.21c.14,.08,.26,.2,.35,.34s.13,.32,.13,.55c0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                <path className="cls-2"
                                      d="M256.69,215.7v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11s-.27-.19-.36-.35c-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                <path className="cls-2"
                                      d="M259.95,219.12v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4,.19-.1,.38-.14,.56-.14,.12,0,.24,.01,.35,.04s.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08s-.17-.03-.25-.03c-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.08-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                <path className="cls-2"
                                      d="M264.39,219.18c-.3,0-.56-.05-.78-.16-.22-.1-.39-.25-.51-.44-.12-.19-.18-.4-.18-.65,0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19,.18-.04,.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03-.13,.02-.24,.07-.34,.14-.07,.05-.12,.12-.16,.2-.04,.08-.06,.17-.06,.28,0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51c-.22,.12-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7s-.42-.25-.75-.25c-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                <path className="cls-2"
                                      d="M270.17,219.18c-.26,0-.5-.05-.72-.14-.22-.09-.41-.22-.56-.38-.16-.16-.27-.34-.34-.54l.54-.25c.05,.13,.13,.26,.22,.37,.09,.11,.21,.2,.36,.27,.14,.07,.31,.1,.5,.1,.26,0,.45-.06,.59-.17,.14-.11,.2-.26,.2-.43,0-.14-.04-.25-.12-.33s-.18-.15-.31-.2-.27-.1-.42-.14c-.16-.04-.31-.08-.47-.13-.16-.05-.3-.11-.43-.19-.13-.08-.23-.18-.31-.31-.08-.13-.12-.29-.12-.49,0-.21,.04-.39,.12-.52,.08-.13,.18-.23,.31-.31,.13-.07,.26-.14,.4-.18,.14-.05,.27-.09,.4-.14,.13-.04,.23-.1,.31-.16,.08-.06,.12-.15,.12-.26s-.05-.22-.14-.3c-.09-.08-.21-.14-.36-.18-.15-.04-.3-.06-.46-.06-.27,0-.48,.03-.65,.09-.17,.06-.3,.14-.39,.24-.09,.1-.16,.21-.2,.33s-.06,.24-.07,.36-.01,.22-.01,.31v3.67h-.7v-3.67c0-.25,.03-.48,.08-.72,.05-.23,.15-.44,.29-.63s.34-.34,.6-.45,.6-.17,1.02-.17c.33,0,.61,.04,.86,.14s.45,.22,.59,.4c.14,.17,.22,.38,.22,.63,0,.19-.04,.35-.12,.47s-.18,.22-.31,.3c-.12,.08-.26,.14-.4,.19s-.27,.1-.4,.15c-.12,.05-.23,.11-.31,.18-.08,.07-.12,.17-.12,.29,0,.11,.04,.2,.12,.26,.08,.06,.18,.12,.31,.16s.27,.08,.43,.12c.16,.04,.31,.08,.47,.13,.15,.05,.3,.12,.42,.2,.13,.08,.23,.19,.31,.33,.08,.14,.12,.31,.12,.53s-.04,.39-.12,.54c-.08,.16-.19,.28-.32,.38-.14,.1-.29,.18-.46,.23-.17,.05-.35,.08-.54,.08Z"/>
                                <path className="cls-2"
                                      d="M274.16,219.18c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66s.33-.34,.56-.46c.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43s.25,.38,.32,.61c.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15s0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.07,.33-.19,.48-.36l.47,.42c-.14,.15-.3,.28-.46,.38s-.33,.17-.51,.22-.38,.07-.6,.07Zm1.02-2.18s.02-.09,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25s-.3-.09-.48-.09c-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42-.09,.18-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M217.17,215.13c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="211.93" cy="214.97" r="2.33"/>
                            </g>
                            <g>
                                <use width="11.79" height="11.91" transform="translate(235.67 223.26)"
                                     xlinkHref="#_02"/>
                                <use width="11.79" height="11.91" transform="translate(249.46 223.26)"
                                     xlinkHref="#_08"/>
                                <use width="11.79" height="11.91" transform="translate(221.88 223.26)"
                                     xlinkHref="#_01"/>
                            </g>
                            <line className="cls-7" x1="201.09" y1="151.05" x2="64.26" y2="287.78"/>
                            <polyline className="cls-11"
                                      points="119.39 185.34 119.4 191.36 134.92 206.65 60.12 281.27"/>
                            <g>
                                <path className="cls-19"
                                      d="M123.24,225.11c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="118" cy="224.95" r="2.33"/>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M123.52,160.42c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="118.29" cy="160.26" r="2.33"/>
                            </g>
                            <g>
                                <use width="11.79" height="11.79" transform="translate(138.22 221.5)"
                                     xlinkHref="#_03"/>
                                <use width="11.79" height="11.91" transform="translate(152.01 221.5)"
                                     xlinkHref="#_07"/>
                                <use width="11.79" height="11.9" transform="translate(165.8 221.5)" xlinkHref="#_17"/>
                            </g>
                            <g>
                                <use width="11.79" height="11.91" transform="translate(292.23 70.82)"
                                     xlinkHref="#_09"/>
                                <use width="11.79" height="11.79" transform="translate(264.65 70.82)"
                                     xlinkHref="#_03"/>
                                <use width="11.79" height="11.91" transform="translate(278.44 70.82)"
                                     xlinkHref="#_07"/>
                            </g>
                            <g>
                                <path className="cls-19" d="M252.54,11.11V6.47h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M255.93,11.11V5.54h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M261.48,11.11V5.54h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M265.63,11.11l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19" d="M272.02,11.11V6.47h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M275.41,11.11V5.54h1.84c.45,0,.82,.07,1.1,.2,.28,.13,.48,.32,.61,.56s.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06,.15-.04,.27-.11,.36-.22s.14-.25,.14-.44-.04-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19" d="M280.29,11.11V5.54h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M284.84,11.24c-.53,0-.99-.12-1.4-.36-.4-.24-.72-.58-.94-1.02-.22-.44-.34-.95-.34-1.53s.11-1.1,.34-1.54c.22-.44,.54-.78,.94-1.02,.4-.24,.87-.36,1.4-.36s1,.12,1.4,.36c.4,.24,.72,.58,.94,1.02,.22,.44,.34,.95,.34,1.54s-.11,1.09-.34,1.53c-.23,.44-.54,.78-.94,1.02s-.87,.36-1.4,.36Zm0-.99c.34,0,.63-.08,.88-.24,.24-.16,.43-.38,.56-.67,.13-.29,.2-.63,.2-1.02s-.07-.74-.2-1.02c-.13-.29-.32-.51-.56-.67-.24-.16-.53-.24-.88-.24s-.61,.08-.86,.23c-.25,.15-.44,.38-.57,.66s-.2,.63-.2,1.03,.07,.74,.2,1.02c.13,.29,.32,.51,.57,.66,.25,.15,.53,.23,.86,.23Z"/>
                                <path className="cls-19"
                                      d="M251.26,20.71v-5.58h3.73v.93h-2.7v1.33h2.34v.92h-2.34v2.4h-1.03Z"/>
                                <path className="cls-19" d="M255.72,20.71v-5.58h1.03v5.58h-1.03Z"/>
                                <path className="cls-19"
                                      d="M260.21,20.84c-.51,0-.96-.12-1.35-.37-.39-.25-.7-.59-.92-1.02-.22-.44-.34-.95-.34-1.53s.11-1.09,.34-1.52c.22-.43,.54-.77,.94-1.02,.4-.25,.87-.37,1.4-.37,.59,0,1.1,.15,1.53,.44,.43,.3,.74,.7,.92,1.22l-.98,.36c-.12-.33-.3-.58-.56-.76s-.56-.27-.92-.27c-.33,0-.62,.08-.87,.23s-.44,.37-.57,.66c-.13,.29-.2,.63-.2,1.03s.07,.74,.2,1.03c.13,.29,.32,.51,.57,.66,.25,.15,.54,.23,.87,.23,.17,0,.35-.02,.53-.07,.18-.05,.35-.13,.5-.25,.15-.12,.27-.29,.37-.5,.09-.21,.14-.49,.14-.82v-.32l.23,.72h-1.6v-.93h2.29v3.03h-.91l-.14-.9,.18,.14c-.1,.2-.23,.36-.39,.5s-.35,.23-.56,.3c-.21,.07-.44,.1-.68,.1Z"/>
                                <path className="cls-19"
                                      d="M265.8,20.84c-.7,0-1.24-.18-1.6-.54-.36-.36-.54-.89-.54-1.61v-3.56h1.03v3.44c0,.29,.03,.53,.09,.72,.06,.19,.17,.34,.34,.44,.16,.1,.39,.15,.68,.15s.51-.05,.68-.15c.16-.1,.28-.25,.34-.44,.06-.19,.1-.43,.1-.72v-3.45h1.03v3.52c0,.73-.18,1.27-.54,1.64-.36,.37-.9,.55-1.6,.55Z"/>
                                <path className="cls-19"
                                      d="M268.96,20.71v-5.58h1.84c.45,0,.82,.07,1.1,.2,.28,.13,.48,.32,.61,.56s.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06,.15-.04,.27-.11,.36-.22s.14-.25,.14-.44-.04-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M273.84,20.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M278.47,20.71v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19" d="M287.34,20.71v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M290.73,20.71v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M296.28,20.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M300.43,20.71l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19" d="M306.82,20.71v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                <path className="cls-19"
                                      d="M310.21,20.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M314.84,20.71v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                <path className="cls-19"
                                      d="M319.72,20.71v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M324.79,20.71l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M332.53,20.84c-.7,0-1.24-.18-1.6-.54-.36-.36-.54-.89-.54-1.61v-3.56h1.03v3.44c0,.29,.03,.53,.09,.72,.06,.19,.17,.34,.34,.44,.16,.1,.39,.15,.68,.15s.51-.05,.68-.15c.16-.1,.28-.25,.34-.44,.06-.19,.1-.43,.1-.72v-3.45h1.03v3.52c0,.73-.18,1.27-.54,1.64-.36,.37-.9,.55-1.6,.55Z"/>
                                <path className="cls-19"
                                      d="M337.54,20.82c-.59,0-1.08-.15-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36-.08-.1-.2-.19-.36-.28-.16-.09-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46s-.32-.16-.51-.16c-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54,.12,.21,.18,.46,.18,.75,0,.33-.08,.63-.24,.88-.16,.26-.39,.46-.68,.6s-.63,.22-1.02,.22Z"/>
                                <path className="cls-19"
                                      d="M342.38,20.71v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                <path className="cls-19"
                                      d="M347.45,20.71l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                <path className="cls-19"
                                      d="M353.31,20.71v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19"
                                      d="M358.83,20.71v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                <path className="cls-19"
                                      d="M366.79,20.84c-.53,0-.99-.12-1.4-.36s-.72-.58-.94-1.02c-.22-.44-.34-.95-.34-1.53s.11-1.1,.34-1.54c.22-.44,.54-.78,.94-1.02s.87-.36,1.4-.36,1,.12,1.4,.36c.4,.24,.72,.58,.94,1.02,.22,.44,.34,.95,.34,1.54s-.11,1.09-.34,1.53c-.23,.44-.54,.78-.94,1.02s-.87,.36-1.4,.36Zm0-.99c.34,0,.63-.08,.88-.24,.24-.16,.43-.38,.56-.67,.13-.29,.2-.63,.2-1.02s-.07-.74-.2-1.02c-.13-.29-.32-.51-.56-.67-.24-.16-.53-.24-.88-.24s-.61,.08-.86,.23-.44,.38-.57,.66-.2,.63-.2,1.03,.07,.74,.2,1.02c.13,.29,.32,.51,.57,.66,.25,.15,.53,.23,.86,.23Z"/>
                                <path className="cls-19"
                                      d="M371.82,20.71l-2.23-5.58h1.14l1.66,4.3h-.33l1.62-4.3h1.14l-2.22,5.58h-.78Z"/>
                                <path className="cls-19"
                                      d="M375.43,20.71v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                <path className="cls-19"
                                      d="M380.05,20.71v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56,.2,.53,.2,.86c0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2-.15-.03-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                            </g>
                            <g>
                                <use width="11.79" height="11.91" transform="translate(319.86 24.48)"
                                     xlinkHref="#_02"/>
                                <use width="11.79" height="11.91" transform="translate(306.07 24.48)"
                                     xlinkHref="#_01"/>
                                <use width="11.79" height="11.91" transform="translate(333.66 24.48)"
                                     xlinkHref="#_08"/>
                            </g>
                            <g>
                                <g>
                                    <path className="cls-19"
                                          d="M257.75,174.74c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                    <circle className="cls-4" cx="252.52" cy="174.58" r="2.33"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="cls-19"
                                              d="M247.82,154.51v-5.58h1.03v5.58h-1.03Zm.82-1.68v-1.31l2.45-2.58h1.39l-3.84,3.9Zm2.63,1.68l-1.76-2.81,.77-.72,2.22,3.53h-1.22Z"/>
                                        <path className="cls-19" d="M253.12,154.51v-5.58h1.03v5.58h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M255.22,154.51v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M260.74,154.51v-5.58h1.62c.93,0,1.65,.25,2.15,.74,.51,.49,.76,1.19,.76,2.1s-.25,1.56-.75,2.04c-.5,.47-1.22,.71-2.17,.71h-1.62Zm1.03-.95h.48c.42,0,.78-.05,1.07-.16,.29-.11,.52-.29,.68-.56s.24-.64,.24-1.11-.08-.82-.23-1.09c-.15-.27-.37-.46-.65-.58-.28-.11-.62-.17-1.01-.17h-.58v3.67Z"/>
                                        <path className="cls-19"
                                              d="M266.13,154.51v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                        <path className="cls-19"
                                              d="M270.75,154.51v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                        <path className="cls-19"
                                              d="M276.8,154.51v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                        <path className="cls-19"
                                              d="M280.19,154.51v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M285.74,154.51v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                        <path className="cls-19"
                                              d="M289.89,154.51l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                        <path className="cls-19"
                                              d="M296.28,154.51v-4.65h-1.73v-.93h4.5v.93h-1.72v4.65h-1.05Z"/>
                                        <path className="cls-19"
                                              d="M299.67,154.51v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                        <path className="cls-19"
                                              d="M304.3,154.51v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                        <path className="cls-19"
                                              d="M309.18,154.51v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M314.25,154.51l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                        <path className="cls-19"
                                              d="M321.99,154.64c-.7,0-1.24-.18-1.6-.54-.36-.36-.54-.89-.54-1.61v-3.56h1.03v3.44c0,.29,.03,.53,.09,.72,.06,.2,.17,.34,.34,.44,.16,.1,.39,.15,.68,.15s.51-.05,.68-.15c.16-.1,.28-.25,.34-.44,.06-.19,.1-.43,.1-.72v-3.45h1.03v3.52c0,.73-.18,1.27-.54,1.64-.36,.37-.9,.55-1.6,.55Z"/>
                                        <path className="cls-19"
                                              d="M327,154.61c-.59,0-1.08-.16-1.46-.46-.38-.31-.61-.72-.69-1.23l1.01-.25c.06,.34,.19,.6,.4,.77,.21,.17,.47,.26,.78,.26,.17,0,.31-.03,.44-.08,.13-.06,.23-.14,.31-.25s.12-.24,.12-.39c0-.14-.04-.26-.12-.36s-.2-.2-.36-.28c-.16-.08-.37-.17-.61-.26-.35-.14-.65-.29-.88-.44s-.41-.34-.53-.55c-.12-.21-.18-.45-.18-.74,0-.3,.07-.56,.22-.79,.15-.23,.35-.41,.62-.54,.26-.13,.57-.2,.92-.2,.37,0,.71,.09,1,.26,.29,.17,.52,.43,.7,.77l-.82,.5c-.11-.2-.24-.36-.4-.46-.15-.1-.32-.16-.51-.16-.13,0-.25,.03-.36,.08-.1,.05-.19,.12-.25,.21-.06,.09-.09,.19-.09,.31s.04,.23,.11,.33c.07,.1,.19,.19,.35,.28,.16,.09,.38,.18,.64,.28,.35,.13,.64,.27,.87,.42,.23,.15,.41,.33,.53,.54s.18,.46,.18,.75c0,.33-.08,.62-.24,.88-.16,.26-.39,.46-.68,.6-.29,.14-.63,.22-1.02,.22Z"/>
                                        <path className="cls-19"
                                              d="M331.84,154.51v-5.58h1.03v2.23h2.41v-2.23h1.03v5.58h-1.03v-2.39h-2.41v2.39h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M336.91,154.51l2.24-5.58h.78l2.24,5.58h-1.13l-1.49-4.05-1.51,4.05h-1.13Zm1.47-1.09l.32-.93h1.68l.34,.93h-2.34Z"/>
                                        <path className="cls-19"
                                              d="M342.78,154.51v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M348.29,154.51v-5.58h1.06l2.6,4.22h-.26v-4.22h1.03v5.58h-1.07l-2.59-4.23h.26v4.23h-1.03Z"/>
                                        <path className="cls-19"
                                              d="M356.25,154.64c-.53,0-.99-.12-1.4-.36-.4-.24-.72-.58-.94-1.02-.22-.44-.34-.95-.34-1.53s.11-1.1,.34-1.54c.22-.44,.54-.78,.94-1.02,.4-.24,.87-.36,1.4-.36s1,.12,1.4,.36c.4,.24,.72,.58,.94,1.02,.22,.44,.34,.95,.34,1.54s-.11,1.09-.34,1.53-.54,.78-.94,1.02c-.4,.24-.87,.36-1.4,.36Zm0-.99c.34,0,.63-.08,.88-.24,.24-.16,.43-.38,.56-.67,.13-.29,.2-.63,.2-1.02s-.07-.74-.2-1.02c-.13-.29-.32-.51-.56-.67-.24-.16-.53-.24-.88-.24s-.61,.08-.86,.23c-.25,.15-.44,.38-.57,.66s-.2,.63-.2,1.03,.07,.74,.2,1.02,.32,.51,.57,.66,.53,.23,.86,.23Z"/>
                                        <path className="cls-19"
                                              d="M361.28,154.51l-2.23-5.58h1.14l1.66,4.3h-.33l1.62-4.3h1.14l-2.22,5.58h-.78Z"/>
                                        <path className="cls-19"
                                              d="M364.89,154.51v-5.58h3.71v.93h-2.68v1.33h2.5v.92h-2.5v1.47h2.68v.93h-3.71Z"/>
                                        <path className="cls-19"
                                              d="M369.51,154.51v-5.58h1.84c.45,0,.82,.07,1.1,.2s.48,.32,.61,.56c.13,.24,.2,.53,.2,.86,0,.51-.15,.91-.44,1.19-.29,.28-.71,.44-1.24,.47-.12,0-.25,0-.38,0h-.66v2.3h-1.03Zm1.03-3.22h.7c.18,0,.34-.02,.48-.06s.27-.11,.36-.22c.09-.1,.14-.25,.14-.44s-.05-.35-.14-.45c-.09-.1-.21-.17-.36-.2s-.31-.05-.48-.05h-.7v1.42Zm2,3.22l-1.4-2.42,1.06-.13,1.54,2.54h-1.19Z"/>
                                        <path className="cls-2"
                                              d="M247.91,164.11v-5.58h1.78c.53,0,.94,.12,1.24,.37,.3,.25,.45,.59,.45,1.02,0,.28-.06,.52-.18,.73-.12,.21-.3,.37-.52,.47,.33,.09,.57,.26,.74,.5s.25,.54,.25,.91c0,.5-.16,.89-.47,1.16-.31,.27-.75,.41-1.32,.41h-1.98Zm.7-3.2h1.19c.27,0,.48-.08,.64-.24s.24-.37,.24-.64-.09-.47-.26-.62c-.18-.15-.42-.22-.74-.22h-1.08v1.73Zm0,2.55h1.22c.76,0,1.14-.32,1.14-.96,0-.3-.09-.54-.26-.71-.17-.17-.42-.25-.73-.25h-1.38v1.92Z"/>
                                        <path className="cls-2"
                                              d="M252.67,164.11v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4s.38-.14,.56-.14c.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                        <path className="cls-2"
                                              d="M257.11,164.17c-.3,0-.56-.05-.78-.16-.22-.1-.39-.25-.51-.44s-.18-.4-.18-.65c0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19,.18-.03,.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03s-.24,.07-.34,.14c-.07,.05-.12,.12-.16,.2s-.06,.17-.06,.28c0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7-.17-.17-.42-.25-.75-.25-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                        <path className="cls-2"
                                              d="M261.47,164.17c-.28,0-.53-.06-.74-.17-.21-.11-.37-.28-.48-.51-.11-.22-.16-.5-.16-.83v-2.5h.67v2.26c0,.26,.03,.48,.1,.65,.07,.17,.17,.3,.3,.38,.13,.08,.3,.12,.5,.12,.22,0,.4-.05,.56-.16,.15-.1,.27-.26,.35-.48s.12-.49,.12-.82v-1.96h.67v3.94h-.67v-.84h.17c-.11,.29-.29,.52-.54,.67-.25,.15-.53,.23-.85,.23Z"/>
                                        <path className="cls-2"
                                              d="M264.51,164.11v-3.94h.68v.84l-.16,.06c.08-.2,.18-.38,.3-.52,.13-.14,.28-.25,.46-.33,.18-.08,.38-.12,.6-.12,.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.7-.22-.88-.15-.19-.37-.28-.67-.28-.22,0-.4,.05-.56,.15-.15,.1-.27,.26-.35,.48-.08,.22-.12,.49-.12,.83v1.97h-.68Z"/>
                                        <path className="cls-2"
                                              d="M270.19,164.17c-.27,0-.51-.04-.72-.12-.21-.08-.4-.19-.55-.32-.15-.13-.27-.27-.36-.42l.47-.4c.1,.2,.25,.37,.45,.5s.44,.2,.72,.2c.25,0,.45-.05,.6-.16s.22-.25,.22-.43c0-.15-.04-.27-.14-.35s-.21-.15-.36-.21-.31-.1-.49-.14c-.15-.04-.3-.08-.45-.14-.15-.05-.29-.12-.41-.2-.12-.08-.22-.18-.3-.31-.08-.13-.11-.29-.11-.47,0-.33,.13-.59,.38-.79s.58-.3,1-.3c.3,0,.57,.06,.82,.19s.44,.31,.58,.56l-.43,.32c-.11-.19-.25-.32-.42-.4-.17-.08-.36-.12-.57-.12s-.38,.04-.52,.13c-.14,.08-.21,.2-.21,.35,0,.11,.03,.2,.1,.27,.06,.07,.16,.13,.28,.18s.28,.09,.46,.14c.17,.04,.33,.08,.5,.14,.17,.05,.33,.12,.47,.21,.14,.09,.26,.2,.35,.34,.09,.14,.13,.32,.13,.55,0,.25-.06,.46-.18,.65-.12,.19-.3,.33-.52,.43-.22,.1-.49,.15-.79,.15Z"/>
                                        <path className="cls-2"
                                              d="M274.29,164.17c-.29,0-.55-.05-.78-.16-.23-.11-.42-.25-.57-.44-.16-.19-.27-.41-.35-.65s-.12-.51-.12-.78,.04-.53,.12-.78,.2-.46,.35-.65c.15-.19,.34-.34,.57-.44,.23-.11,.49-.16,.78-.16,.37,0,.7,.09,.98,.27,.28,.18,.49,.46,.62,.83l-.6,.23c-.07-.22-.19-.41-.36-.54-.17-.14-.38-.21-.64-.21s-.48,.07-.65,.21c-.17,.14-.3,.32-.38,.54-.08,.22-.12,.45-.12,.7s.04,.49,.12,.71c.08,.22,.21,.4,.38,.54,.17,.14,.39,.2,.65,.2s.47-.07,.64-.2c.17-.14,.29-.32,.36-.55l.6,.23c-.13,.37-.34,.65-.62,.84-.28,.18-.61,.28-.98,.28Z"/>
                                        <path className="cls-2"
                                              d="M276.79,164.11v-5.58h.68v2.47l-.17,.06c.08-.2,.18-.38,.31-.52,.13-.14,.29-.25,.47-.33,.18-.08,.38-.12,.59-.12,.43,0,.77,.13,1.02,.38,.25,.25,.37,.63,.37,1.12v2.5h-.68v-2.26c0-.4-.07-.7-.22-.88-.15-.19-.37-.28-.67-.28-.33,0-.58,.12-.76,.35-.18,.23-.27,.6-.27,1.11v1.97h-.68Z"/>
                                        <path className="cls-2"
                                              d="M281.94,164.11l-1.17-3.94h.72l.8,2.92,.94-2.92h.6l.94,2.9,.81-2.9h.72l-1.18,3.94h-.62l-.97-3-.97,3h-.63Z"/>
                                        <path className="cls-2"
                                              d="M288.77,164.17c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46,.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43,.14,.18,.25,.38,.32,.61,.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.08,.33-.19,.48-.36l.47,.42c-.14,.16-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.08,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42s-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                        <path className="cls-2"
                                              d="M291.35,159.45v-.92h.8v.92h-.8Zm.06,4.66v-3.94h.67v3.94h-.67Z"/>
                                        <path className="cls-2"
                                              d="M294.83,165.81c-.57,0-1.01-.1-1.34-.31-.33-.21-.5-.5-.5-.86,0-.03,0-.06,0-.1s0-.08,.02-.12l.98-.71,.23,.16-.56,.7s0,.04,0,.06v.05c0,.21,.11,.37,.34,.48,.22,.1,.5,.16,.83,.16,.29,0,.54-.04,.73-.12,.19-.08,.29-.22,.29-.4,0-.11-.03-.19-.09-.26s-.15-.12-.26-.16c-.12-.04-.25-.07-.4-.1-.15-.03-.33-.05-.52-.08-.24-.04-.47-.09-.7-.16-.23-.07-.41-.17-.56-.3-.15-.13-.22-.31-.22-.53,0-.19,.07-.36,.2-.51,.13-.15,.3-.28,.52-.38l.43,.28c-.18,.04-.31,.11-.38,.2-.07,.09-.11,.19-.11,.32,0,.1,.04,.18,.11,.25,.07,.06,.18,.11,.31,.15,.14,.04,.3,.07,.49,.09,.26,.04,.5,.08,.72,.12,.22,.04,.42,.1,.6,.18,.17,.08,.31,.18,.4,.31,.1,.13,.14,.3,.14,.51,0,.36-.15,.64-.45,.84-.3,.19-.71,.29-1.23,.29Zm-.1-2.94c-.3,0-.57-.06-.81-.18-.23-.12-.42-.28-.55-.49s-.2-.45-.2-.71,.07-.52,.2-.73c.14-.21,.32-.38,.55-.49,.23-.12,.49-.18,.77-.18s.53,.06,.76,.18c.23,.12,.41,.28,.55,.5,.14,.21,.21,.46,.21,.75,0,.27-.06,.51-.19,.71-.13,.2-.3,.36-.53,.47-.22,.11-.48,.17-.78,.17Zm-.02-.55c.27,0,.47-.08,.62-.24,.15-.16,.22-.36,.22-.6,0-.25-.08-.45-.23-.61-.16-.15-.36-.23-.61-.23s-.46,.08-.62,.24c-.16,.16-.24,.36-.24,.6s.08,.44,.23,.6c.16,.16,.37,.24,.63,.24Zm1.26-1.42l-.49-.33,.83-.69,.34,.42-.69,.59Z"/>
                                        <path className="cls-2"
                                              d="M298.94,164.17c-.31,0-.58-.05-.81-.16-.23-.1-.42-.25-.58-.44-.15-.19-.27-.4-.34-.64-.08-.24-.12-.5-.12-.77s.04-.53,.11-.78c.08-.25,.19-.47,.33-.66,.15-.19,.33-.34,.56-.46,.22-.11,.49-.17,.78-.17s.57,.05,.79,.16c.22,.1,.4,.25,.54,.43,.14,.18,.25,.38,.32,.61,.07,.22,.1,.46,.1,.7,0,.02,0,.07,0,.15,0,.08,0,.15-.02,.2h-2.84c.02,.38,.13,.68,.34,.9,.21,.21,.49,.32,.85,.32,.23,0,.43-.04,.6-.11,.17-.08,.33-.19,.48-.36l.47,.42c-.14,.16-.3,.28-.46,.38s-.33,.17-.51,.22c-.18,.05-.38,.07-.6,.07Zm1.02-2.18s.02-.08,.02-.13c0-.04,0-.08,0-.13,0-.13-.02-.26-.07-.38s-.11-.24-.2-.35c-.09-.1-.2-.19-.34-.25-.14-.06-.3-.09-.48-.09-.23,0-.43,.05-.59,.16-.17,.11-.29,.25-.38,.42s-.14,.37-.16,.58h2.42l-.22,.16Z"/>
                                        <path className="cls-2"
                                              d="M301.59,164.11v-3.94h.68v1.06l-.18,.04c.04-.23,.13-.44,.27-.61,.14-.17,.31-.31,.5-.4s.38-.14,.56-.14c.12,0,.24,.01,.35,.04,.11,.03,.23,.07,.34,.14l-.34,.61c-.06-.03-.14-.06-.23-.08-.09-.02-.17-.03-.25-.03-.13,0-.25,.02-.38,.07-.13,.05-.24,.12-.34,.22-.1,.1-.18,.24-.23,.41-.02,.07-.04,.17-.05,.29-.01,.12-.02,.28-.02,.49v1.85h-.68Z"/>
                                        <path className="cls-2"
                                              d="M306.99,164.11v-5.58h1.66c.6,0,1.06,.13,1.38,.4s.48,.66,.48,1.18-.16,.91-.48,1.19c-.32,.28-.78,.41-1.38,.41h-.97v2.4h-.7Zm.7-3.02h.9c.38,0,.68-.08,.9-.24,.22-.16,.32-.4,.32-.73s-.11-.57-.32-.72c-.21-.16-.51-.23-.91-.23h-.9v1.92Z"/>
                                        <path className="cls-2"
                                              d="M312.15,164.17c-.24,0-.43-.07-.58-.22s-.22-.39-.22-.71v-4.7h.67v4.58c0,.15,.03,.27,.1,.34,.06,.07,.15,.1,.26,.1,.05,0,.1,0,.16-.02s.12-.03,.18-.05v.58c-.09,.04-.19,.06-.29,.08-.1,.02-.19,.03-.27,.03Z"/>
                                        <path className="cls-2"
                                              d="M314.77,164.17c-.3,0-.56-.05-.78-.16-.22-.1-.39-.25-.51-.44s-.18-.4-.18-.65c0-.22,.04-.4,.13-.56,.09-.15,.2-.27,.34-.36,.14-.09,.3-.15,.48-.19,.18-.03,.37-.05,.56-.05h1.28v.51h-1.18c-.12,0-.24,.01-.37,.03s-.24,.07-.34,.14c-.07,.05-.12,.12-.16,.2s-.06,.17-.06,.28c0,.21,.08,.37,.23,.5,.16,.12,.36,.18,.62,.18,.22,0,.42-.05,.6-.15,.18-.1,.32-.24,.43-.42s.16-.38,.16-.6l.28,.29c-.03,.28-.12,.53-.26,.75s-.32,.39-.53,.51-.47,.18-.75,.18Zm1.3-.06l-.05-.79v-1.68c0-.3-.08-.54-.25-.7-.17-.17-.42-.25-.75-.25-.21,0-.41,.04-.6,.13-.19,.09-.37,.24-.54,.44l-.49-.42c.22-.26,.47-.44,.75-.56,.28-.12,.59-.18,.93-.18,.52,0,.92,.13,1.2,.4,.28,.27,.42,.65,.42,1.14v2.47h-.62Z"/>
                                        <path className="cls-2"
                                              d="M317.31,160.69v-.53h2.42v.53h-2.42Zm1.81,3.48c-.19,0-.36-.04-.51-.11-.15-.07-.27-.19-.36-.35-.09-.16-.13-.37-.13-.62v-3.99l.69-.38v4.2c0,.22,.03,.39,.1,.51,.07,.12,.19,.18,.38,.18,.05,0,.12,0,.19-.02,.08-.01,.15-.03,.24-.05v.53c-.1,.04-.2,.06-.31,.08-.1,.02-.2,.02-.3,.02Z"/>
                                        <path className="cls-2"
                                              d="M320.29,164.11v-.48l2.5-3.07,.03,.15h-2.42v-.54h3.04v.51l-2.48,3.09-.02-.21h2.49v.55h-3.14Z"/>
                                    </g>
                                    <g>
                                        <use width="11.79" height="11.79" transform="translate(260.98 168.5)"
                                             xlinkHref="#_04"/>
                                        <use width="11.79" height="11.79" transform="translate(274.77 168.5)"
                                             xlinkHref="#_05"/>
                                        <use width="11.79" height="11.79" transform="translate(302.35 168.5)"
                                             xlinkHref="#_16"/>
                                        <use width="11.79" height="11.79" transform="translate(288.56 168.5)"
                                             xlinkHref="#_06"/>
                                    </g>
                                </g>
                            </g>
                            <text className="cls-1" transform="translate(72.09 227.82)">
                                <tspan className="cls-15" x="0" y="0">A</tspan>
                                <tspan className="cls-17" x="5.02" y="0">llerweg</tspan>
                            </text>
                            <text className="cls-3" transform="translate(232.13 118.84)">
                                <tspan x="0" y="0">THE</tspan>
                                <tspan className="cls-16" x="14.84" y="0">A</tspan>
                                <tspan x="19.48" y="0">TER IM</tspan>
                                <tspan className="cls-20" x="46.78" y="0">P</tspan>
                                <tspan className="cls-21" x="50.61" y="0">A</tspan>
                                <tspan x="55.31" y="0">VILLION</tspan>
                            </text>
                            <g>
                                <g>
                                    <use width="11.79" height="11.91" transform="translate(314.44 122.27)"
                                         xlinkHref="#_08"/>
                                    <use width="13.03" height="11.82" transform="translate(355.82 122.27)"
                                         xlinkHref="#_18"/>
                                    <use width="11.79" height="11.91" transform="translate(328.23 122.27)"
                                         xlinkHref="#_09"/>
                                    <use width="11.79" height="11.79" transform="translate(286.86 122.27)"
                                         xlinkHref="#_03"/>
                                    <use width="11.79" height="11.91" transform="translate(300.65 122.27)"
                                         xlinkHref="#_07"/>
                                    <use width="11.79" height="11.82" transform="translate(342.03 122.27)"
                                         xlinkHref="#_10"/>
                                </g>
                                <text className="cls-1" transform="translate(232.13 130.99)">
                                    <tspan x="0" y="0">Hauptbahnh</tspan>
                                    <tspan className="cls-22" x="43.73" y="0">o</tspan>
                                    <tspan x="48.26" y="0">f</tspan>
                                </text>
                            </g>
                            <g>
                                <path className="cls-19"
                                      d="M223.45,114.6c0,2.89-5.24,8.39-5.24,8.39,0,0-5.24-5.5-5.24-8.39s2.34-5.24,5.24-5.24,5.24,2.34,5.24,5.24Z"/>
                                <circle className="cls-4" cx="218.22" cy="114.44" r="2.33"/>
                            </g>
                            <text className="cls-1" transform="translate(250.72 32.77)">
                                <tspan x="0" y="0">Büttne</tspan>
                                <tspan className="cls-23" x="23.42" y="0">r</tspan>
                                <tspan x="26.55" y="0">st</tspan>
                                <tspan className="cls-6" x="33.08" y="0">r</tspan>
                                <tspan x="36.23" y="0">aße</tspan>
                            </text>
                            <use width="11.79" height="11.82" transform="translate(88.26 167.38)" xlinkHref="#_10"/>
                        </g>
                        <g id="Ebene_2"><a
                            xlinkHref="https://www.google.com/maps/dir//Figurentheaterhaus+Hannover+gemeinn%C3%BCtzige+GmbH,+Gro%C3%9Fer+Kolonnenweg,+Hannover/@52.4010171,9.7374364,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b07341a00b65ff:0x6d54696a8f5da6b4!2m2!1d9.7367954!2d52.4048424">
                            <rect className="cls-5" x="233.01" y="3.36" width="150.23" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir/52.3949788,9.7730966/Theater+in+der+List,+Spichernstra%C3%9Fe,+Hannover/@52.3908945,9.7388834,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b073580bad6f9d:0x584a4bcc57949447!2m2!1d9.7397577!2d52.3871834">
                            <rect className="cls-5" x="249.29" y="48.45" width="95.41" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//Theater+im+Pavillon,+Lister+Meile,+Hannover/@52.3804316,9.6749827,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47b0751f96ef399b:0xac61e0b7aa5dc403!2m2!1d9.7450221!2d52.3803475">
                            <rect className="cls-5" x="212.98" y="99.83" width="157.3" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//Klecks+Theater+Hannover+e.V.,+Kestnerstra%C3%9Fe,+Hannover/@52.3714044,9.6857865,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47b00b4c38f01caf:0x5c6d2199d36def36!2m2!1d9.7558259!2d52.3713203">
                            <rect className="cls-5" x="243.22" y="148.05" width="130.51" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//EISFABRIK,+Seilerstra%C3%9Fe,+Hannover/@52.3824362,9.7111955,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b00b4a76241523:0x5844f91688e04302!2m2!1d9.7521734!2d52.3652496">
                            <rect className="cls-5" x="206.02" y="202.46" width="79.96" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//THEATER+AN+DER+GLOCKSEE,+Glockseestra%C3%9Fe,+Hannover/@52.3818011,9.724065,14z/data=!3m2!4b1!5s0x47b074e9ee4ebd31:0x743e6e9432c1e45c!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b07495fab74f77:0x2559d253510b5d67!2m2!1d9.719333!2d52.37164">
                            <rect className="cls-5" x="56.18" y="112.35" width="68.04" height="69.23"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//Compagnie+Fredewe%C3%9F,+Ilse-Ter-Meer-Weg,+Hannover/@52.3788345,9.7124589,14z/data=!3m2!4b1!5s0x47b074e9ee4ebd31:0x743e6e9432c1e45c!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b074e9f85d66dd:0x8d1176423efea180!2m2!1d9.7170999!2d52.36152">
                            <rect className="cls-5" x="37.19" y="198.37" width="90.41" height="35.62"/>
                        </a><a
                            xlinkHref="https://www.google.com/maps/dir//quartier+theater/@52.3911356,9.7140138,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47b0749f956f75d9:0xea6b45ac57efd15f!2m2!1d9.724989!2d52.3867359">
                            <rect className="cls-5" x="64.26" y="72.29" width="83.78" height="35.62"/>
                        </a></g>
                    </svg>
                </div>
            </div>
        </div>
    </div>

};

export default HostingOrganization;

