import React from 'react';
import {NavLink} from "react-router-dom";
import PartnerLogos from "./Image/PartnerLogos";
import {Link, Outlet, useLocation} from "react-router-dom";
const FooterNav=[
    {
        title:'Über die EISFABRIK',
        link:'/eisfabrik-hannover'
    },
    {
        title:'Geschichte der EISFABRIK',
        link:'/eisfabrik-geschichte'
    },
    {
        title:'Kontakt & Anreise',
        link:'/kontakt'
    }
]
const Footer = () => {
    const { pathname } = useLocation();
/*    console.log(pathname);*/
    const pathname_split = pathname.split( '/' );
/*    console.log(pathname_split);*/
    const shownav=(pathname_split[1]==='app'?false:true)
    return (
        <footer className={'bg-dark pt-0 text-white'}>
            {shownav &&
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-md-2 pb-5 mb-5'}>
                            <img width={'185'} height={'185'} className={'img-fluid mb-3 d-block'}
                                 src={'/static/images/Freies-Theater-Hannover-Logo_neg.svg'}
                                 alt={'Freie Theater Hannover (Logo)'}/>
                        </div>
                        <div className={'col-md-3 pb-5 mb-5 mt-md-5 pt-md-5'}>
                            <p className={'pe-md-3 pt-md-2'}><b>Freie Theater HANNOVER</b><br/>
                                ℅ Landesverband Freier Theater Niedersachsen e.V.<br/>
                                Lister Meile 27, 30161 Hannover<br/><br/>
                                <a className={'d-inline-block me-4'}
                                   href="https://www.facebook.com/freiestheaterhannover/"><img alt={'Facebook'}
                                                                                               width={'24'}
                                                                                               height={'24'}
                                                                                               src={'/static/images/facebook_white.svg'}/></a>
                                <a className={'d-inline-block me-4'}
                                   href="https://www.instagram.com/freiestheaterhannover/"><img alt={'Instagram'}
                                                                                                width={'24'}
                                                                                                height={'24'}
                                                                                                src={'/static/images/instagram_white.svg'}/></a>
                                <a className={'d-inline-block'} href="https://vimeo.com/freiestheaterhannover"><img
                                    alt={'vimeo'} width={'24'} height={'24'} src={'/static/images/vimeo-v.svg'}/></a>
                            </p>
                        </div>
                        <div className={'col-md-3 pb-5 mb-5 mt-md-5 pt-md-5'}>
                            <p className={'pe-md-3 pt-md-2'}><b>REDAKTION</b><br/>
                                minusmalminus - <br/>agentur für musik, kultur & kommunikation<br/>
                                <a href={"mailto:pr@freie-theater-hannover.de"}>pr@freie-theater-hannover.de</a><br/><br/>
                                <a href={"/static/downloads/FTH_mediakit_v2.zip"}>Download Logos FTH</a>
                            </p>

                        </div>
                        <div className={'col-md-3 pb-5 mb-5 mt-md-5 pt-md-5'}>
                            {/*<p className={'pe-md-3 pt-md-2'}><b>GEFÖRDERT DURCH</b><br/></p>*/}
                            <img width={'250'} height={'75'} className={'img-fluid mb-3 d-block pe-md-3 pt-md-2'}
                                 src={'/static/images/laft_white.svg'}
                                 alt={'Landesverband Freie Theater in Niedersachsen (Logo)'}/>
                            <img width={'250'} height={'75'} className={'img-fluid mb-3 d-block'}
                                 src={'/static/images/lhh_250.webp'}
                                 alt={'Kulturbüro der Landeshauptstadt Hannover (Logo)'}/>
                        </div>
                    </div>
                    <div className={'row pt-3 pb-3'}>
                        <div className={'col-12'}>
                            <Link
                                className={`text-white d-inline-block me-3`}
                                to={'/imprint'}
                            >
                                Impressum
                            </Link>
                            <Link
                                className={`text-white d-inline-block `}
                                to={'/privacy'}
                            >
                                Datenschutz
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </footer>
    );
};

export default Footer;