import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {endOfYesterday, format} from "date-fns";
import {de} from "date-fns/locale";
import {getSingeleton} from "../../utils";
import {NavLink} from "react-router-dom";
import {Image} from "@wbe/lazy-image";


class Imprint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            organizationType: props.organizationType,
            startdate: format(endOfYesterday(), 'yyyy-MM-dd', {
                locale: de
            })
        }
    }

    getData() {
        fetch(getSingeleton('imprint'), {
            mode: 'cors',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    populate: 1,
                }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    eventlist: data,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.eventlist.name + ' – Freie Theater Hannover'}</title>
                </Helmet>
                <section className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'fz-140 text-uppercase pb-5 mt-5 hyphenationhyphenation'}>Impressum</h1>
                        </div>
                        <div className={'col-lg-12 pb-5 mb-3'}>
                            <div className={'text-holder hyphennation'}
                                 dangerouslySetInnerHTML={{__html: this.state.eventlist.description}}></div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Imprint;
