import React, {Component} from 'react';
import {getCollectionUrl} from "../utils";
import {NavLink} from "react-router-dom";
import {Image} from "@wbe/lazy-image";

class OrganizationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            organizationType: props.organizationType,
        }
    }

    getData() {
        fetch(getCollectionUrl(this.props.organizationType), {
            mode: 'cors',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    limit: 100,
                    populate: 1,
                    skip: null,
                    sort: {
                        name: 1
                    },
                }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allevents: data.entries,
                    total_chunks: data.entries.length,
                    current_chunks: 0,
                    eventlist: data.entries,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
                <section className={'mt-0 container-fluid'}>
                    <div className={'container-fluid'}>
                    <div className={'row'}>
                        {this.state.eventlist.map((organization, index) => (
                            <div key={'organization-' + index} className={'col-md-6 mb-4 ps-lg-5 pe-lg-5'}>

                                    <NavLink
                                        className={`text-decoration-none text-white`}
                                        to={(this.props.organizationType==='HostingOrganization' ? "/performingartstheatre/" + organization._id : "/performingartsgroup/" + organization._id)}
                                        title={organization.name}
                                    >
                                        <div className={''}>
                                        {organization.image !== null &&
                                            <div className={'img-wrapper card-image'}>
                                                <Image
                                                    srcPlaceholder={'/static/images/placeholder.png'}
                                                    src={process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + organization.image.path + '&w=928&h=523&m=thumbnail&mime=image/webp&q=60&o=1'}
                                                    alt={organization.name}
                                                    width={1920}
                                                    height={1080}
                                                    className={'img-fluid'}
                                                />
                                            </div>
                                        }
                                        </div>
                                    </NavLink>
                                <h2 className={'m-0 pt-4 pb-5 h4 text-center text-uppercase hyphenation'}>
                                    <b className={'d-inline-block'}>{organization.name}</b>
                                </h2>
                            </div>

                        ))}
                    </div>
                    </div>
                </section>
        );
    }
}

export default OrganizationList;

