import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {format, startOfMonth} from 'date-fns';
import {de} from 'date-fns/locale';
import Eventlist from "../../components/Eventlist";
import "keen-slider/keen-slider.min.css"
import SliderDemo from "../../components/Slider/SliderDemo";
import {getCollectionUrl} from "../../utils";

class HomeDefault extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            sliderlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            startdate: format(startOfMonth(new Date()), 'yyyy-MM-dd', {
                locale: de
            }),
            enddate: format(startOfMonth(new Date(new Date().getFullYear(), new Date().getMonth()+1, 1)), 'yyyy-MM-dd', {
                locale: de
            }),

        }
        this.renderShowmore.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getData() {
        console.log(this.props.filterByID);
        let customfilter={
            $and: [
                {startDate: {$gt: this.state.startdate}},
                {startDate: {$lt: this.state.enddate}},
                {premiere: true},
                {published: true},
            ] }

        fetch(getCollectionUrl('Event'), {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                    limit: 1000,
                    populate: 1,
                    skip: null,
                    sort: {
                        startDate:1,
                        time:1,
                        "creativeWork.name":1
                    },
                    filter: customfilter
                }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    sliderlist: data.entries,
                    total_chunks: data.entries.length,
                    current_chunks: 0,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.getData();
    }

    renderTime = function renderTime(time) {
        return <>
            {(time.substring(0, 5) !== "00:00") ?
                <span><b>{time.substring(0, 5)} Uhr</b></span>
                : <span><b>—</b></span>
            }
        </>
    }

    //Interner / externer Link //REACT_APP_HOST
    checkInternalLink(link) {
        const host = process.env.REACT_APP_HOST.replace("https://", "");

        function isExternalLink() {
            const tmp = document.createElement('a');
            tmp.href = link;
            return tmp.host !== host;
        }

        return !isExternalLink();
    }

    renderShowmore = function renderShowmore(show) {
        if (show)
            return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                <button className={'btn btn-lg btn-dark text-uppercase has-secondary-font fw-bold'}
                        onClick={this.handleClick}>+ Weitere anzeigen
                </button>
            </div>;
        return null;
    }

    handleClick(param) {
        this.setState((state) => {
            if (state.current_chunks < state.total_chunks) {
                const pusharray = state.eventlist.concat(state.allevents[(state.current_chunks + 1) * this.state.chunkSize]);
                return {
                    current_chunks: state.current_chunks + 1,
                    eventlist: pusharray
                }
            }
            return null;
        });
    }

    render() {
        return (
            <div className="">
                <Helmet>
                    <title>Freie Theater Hannover</title>
                </Helmet>
                <section className={'container-fluid pt-0'}>
                    <div className={'row'}>
                        <div className={'mt-5 col-8 offset-2 offset-lg-0 col-lg-3 order-md-2 text-start'}>
                            <img width={'600'} height={'600'} className={'img-fluid mb-3 d-block fth-header'} src={'/static/images/fth_black.svg'} alt={'Freie Theater Hannover (Logo)'}/>
                        </div>
                        <div className={'col-lg-9 order-md-1'}>
                            <div className={'p-lg-4 mb-4'}>
                                {(this.state.loading===false && this.state.sliderlist.length>0) &&
                                    <SliderDemo sliderlist={this.state.sliderlist}/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'program'} className={'pb-5 bg-gray'}>
                    <article className={'container'}>
                        <h2 className={'h1 section-heading text-uppercase'}>Spielplan</h2>
                        <Eventlist filterByID={false}/>
                    </article>
                </section>
            </div>
        );
    }
}

export default HomeDefault;

