import React from "react"
import {useKeenSlider} from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import RenderImage from '../Image/RenderImage'
import {Link, NavLink, Outlet, useLocation} from "react-router-dom";
import {Last} from "react-bootstrap/PageItem";
import {format} from "date-fns";
import {de} from "date-fns/locale";
// import 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${
                props.left ? "arrow--left" : "arrow--right"
            } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
            )}
        </svg>
    )
}

export default function SliderDemo(prop) {
    console.log(prop);

    const [loaded, setLoaded] = React.useState([])
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const animation = {duration: 10000, easing: (t) => t}

   const [opacities, setOpacities] = React.useState([])
   let timeout
   let mouseOver = false
   const [sliderRef] = useKeenSlider(
       {
           loop: true,
           perView:1,
           spacing:0
       },
       [
           (slider) => {
               let timeout
               let mouseOver = false
    //
               function clearNextTimeout() {
                   clearTimeout(timeout)
               }
    //
               function nextTimeout() {
                   clearTimeout(timeout)
                   if (mouseOver) return
                   timeout = setTimeout(() => {
                       slider.next()
                   }, 5000)
               }
    //
               slider.on("created", () => {
                   slider.container.addEventListener("mouseover", () => {
                       mouseOver = true
                       clearNextTimeout()
                   })
                   slider.container.addEventListener("mouseout", () => {
                       mouseOver = false
                       nextTimeout()
                   })
                   nextTimeout()
               })
               slider.on("dragStarted", clearNextTimeout)
               slider.on("animationEnded", nextTimeout)
               slider.on("updated", nextTimeout)
           },
       ]
   )
    React.useEffect(() => {
        const new_loaded = [...loaded]
        new_loaded[currentSlide] = true
        setLoaded(new_loaded)
    }, [currentSlide])

    const slidesArray=prop.sliderlist;
/*    <div ref={sliderRef} className="keen-slider">
        {slidesArray.map((slide, idx) => (
            <div key={idx} className="keen-slider__slide lazy__slide">
                {idx}
                <img alt={slide.CreativeWork.name} src={process.env.REACT_APP_API_URL + slide.CreativeWork.image.styles[1].path} />
            </div>
        ))}
    </div>*/
    return (
        <>

            <div ref={sliderRef} className="keen-slider keen-slider-home">
                {slidesArray.map((slide, index) => {
                    if(typeof slide.CreativeWork!=='undefined') {
                        return (
                            <div key={index} className="keen-slider__slide">
                                <div className={'position-relative'}>
                                    <div className={'position-abolute sliderinfo-bg bg-primary'}>
                                        {slide.CreativeWork.image !== undefined &&
                                            <>

                                                <img alt="Slider1" width={1920} height={1080}
                                                     className={'img-fluid w-100'}
                                                     src={process.env.REACT_APP_API_URL + slide.CreativeWork.image.styles[1].path}/>
                                            </>

                                        }

                                    </div>
                                    <div className={'position-abolute sliderinfo-txt'}>
                                        <Link
                                            className={`d-flex w-100 h-100 text-decoration-none`}
                                            to={'/creativework/' + slide.CreativeWork._id}
                                        >
                                            <div className={'mt-auto mb-auto ps-1 ps-lg-5 pe-1 pe-lg-5'}>
                                                <p className={'h4 mb-0 text-outline'}>
                                                    {(typeof slide.CreativeWork.performingGroup === "object" && slide.CreativeWork.performingGroup !== null) ?
                                                        <span
                                                            className={'text-uppercase'}> {slide.CreativeWork.performingGroup.name}</span> :
                                                        <span
                                                            className={'text-uppercase'}>{slide.CreativeWork.alternatePerformer}</span>
                                                    }
                                                </p>
                                                <p className={'h1 section-heading text-uppercase mb-0'}>{slide.CreativeWork.name}</p>
                                                <p className={'h4'}>Premiere {format(new Date(slide.startDate), 'iiiiii dd.MM', {
                                                    locale: de
                                                })} {(slide.time.substring(0, 5) !== "00:00") ?
                                                    <span> – {slide.time.substring(0, 5)}</span>
                                                    : <></>
                                                }
                                                </p>
                                                <p className={'h6 d-none d-md-block'}>@ {(typeof slide.Location.HostingOrganization === "object" && slide.Location.HostingOrganization !== null) ? slide.Location.HostingOrganization.name : slide.Location.name}</p>
                                                <div
                                                    className="mt-2 btn btn-light rounded-pill text-center  d-none d-md-inline-block">
                                                    <b>Mehr Termine</b></div>
                                            </div>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    }else{
                        return false
                    }
                })}
            </div>
        </>
    )
}


