import React, { useState, useEffect } from 'react';
import {Link,NavLink,Outlet,useLocation,useParams, redirect, useNavigate } from "react-router-dom";
import {Navbar,Nav,Container,Offcanvas,NavDropdown} from 'react-bootstrap';
import PropTypes from "prop-types";
import {getUserToken} from "../utils";
/*import {useEffect} from "@types/react";*/
const Navitems=[
    {
        title:'Theater',
        link:'/performingartsgroup'
    },
    {
        title:'Spielstätten',
        link:'/performingartstheatre'
    },
    {
        title:'Über uns',
        link:'/about'
    }

]
const Mainnavigation = () => {
    const [isOpen, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const { pathname } = useLocation();
/*    console.log(pathname);*/
    const pathname_split = pathname.split( '/' );
/*    console.log(pathname_split);*/
    const shownav=(pathname_split[1] !== 'app')




/*    useEffect(() => {
        const getToken = async () => {
            const token=getUserToken();
            if(token!==null){
                setLoggedIn(true)
            }else{
                setLoggedIn(false)
            }
            console.log(loggedIn);
        }
        getToken();
    });*/
    return (
        <>
            {shownav &&

                <>
                    <Container className={'mt-4'}>

                    <Navbar
                        expanded={isOpen}
                        expand={'lg'}
                        bg="light"
                        className=""
                        fixed={"top"}
                    >
                        <Container className={'container'}>
                            <Link className={"navbar-brand ms-4 d-none d-lg-inline"} to="/">
                                <img src="/static/images/fth_nav.svg" alt="FTH" width="278" height="28"/>
                            </Link>
                            <Link className={"navbar-brand ms-3 d-lg-none"} to="/">
                                <img src="/static/images/fth_nav_short.svg" alt="FTH" width="28" height="28"/>
                            </Link>
                            <Navbar.Toggle
                                aria-controls="offcanvasNavbar"
                                aria-expanded={(isOpen ? 'true' : 'false')}
                                onClick={() => setOpen(isOpen ? false : "expanded")}
                            />
                            <Navbar.Offcanvas
                                id="offcanvasNavbar"
                                placement="end"
                            >
                                <Offcanvas.Header
                                    closeButton
                                    className="justify-content-end"
                                    onClick={() => setOpen(false)}
                                ></Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="ps-0 me-auto">
                                        {Navitems?.map((entry, idx) => (
                                            <NavLink
                                                className={"nav-link main-nav-link  text-uppercase text-black fw-bold fs21"}
                                                to={entry.link}
                                                onClick={() => setOpen(false)}
                                                key={idx}
                                            >
                                                {entry.title}
                                            </NavLink>
                                        ))}
                                        <NavLink
                                            className={"nav-link main-nav-link   d-md-none d-lg-none d-xl-block text-uppercase text-black fw-bold fs21"}
                                            to='/fthtv'
                                            onClick={() => setOpen(false)}
                                        >
                                            FTH.TV
                                        </NavLink>
                                    </Nav>
                                    <ul className="navbar-nav d-none d-lg-flex">
                                        <li className="nav-item pe-3">
                                            <a className={'nav-link '}
                                               href="https://www.facebook.com/freietheaterhannover/"><img
                                                alt={'Facebook'} width={'24'} height={'24'}
                                                className={'img-fluid align-self-center'}
                                                src={'/static/images/facebook_white.svg'}/></a>
                                        </li>
                                        <li className="nav-item pe-3">
                                            <a className={'nav-link '}
                                               href="https://www.instagram.com/freietheaterhannover/"><img
                                                alt={'Instagram'} width={'24'} height={'24'}
                                                className={'img-fluid align-self-center'}
                                                src={'/static/images/instagram_white.svg'}/></a>
                                        </li>
                                        <li className="nav-item pe-3">
                                            <a className={'nav-link '}
                                               href="https://vimeo.com/freiestheaterhannover"><img alt={'Instagram'}
                                                                                                   width={'24'}
                                                                                                   height={'24'}
                                                                                                   className={'img-fluid align-self-center'}
                                                                                                   src={'/static/images/vimeo-v.svg'}/></a>
                                        </li>
                                    </ul>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>

                </Container>
                <Outlet />
                    </>
        }
        </>
    );
};

export default Mainnavigation;
