import React, {useEffect} from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import Home from "./views/home/HomeDefault";
import HomeDefaultList from "./views/home/HomeDefaultList";
import HomeDefaultListCurrent from "./views/home/HomeDefaultListCurrent";
import Kontakt from "./views/innerpages/Kontakt";
import Imprint from "./views/innerpages/Imprint";
import NotFound from "./views/innerpages/NotFound";
import Mainnavigation from "./components/Mainnavigation";
import CreativeWork from "./views/innerpages/CreativeWork";
import HostingOrganization from "./views/innerpages/HostingOrganization";
import PerformingOrganization from "./views/innerpages/PerformingOrganization";
import OrganizationItem from "./views/innerpages/OrganizationItem";
import Vimeoblock from "./views/innerpages/Vimeoblock";
import ScrollToTop from "./components/ScrollToTop";
import ScrollTopBehaviour from "./components/ScrollTopBehaviour";
import Footer from "./components/Footer";
import Privacy from "./views/innerpages/Privacy";

const Dashboard = React.lazy(() => import('./backend/views/Dashboard'));
const EditCreativeWork = React.lazy(() => import('./backend/views/innerpages/CreativeWork'));
const AddCreativeWork = React.lazy(() => import('./backend/views/innerpages/AddCreativeWork'));

const App = () => {
    return (
        <>
            <Helmet>
                <title>{process.env.REACT_APP_SITETITLE}</title>
                <meta name="description" content={process.env.REACT_APP_DESC}/>
            </Helmet>
            <Mainnavigation/>
            <main>
                <ScrollTopBehaviour/>
                <Routes>
                    <Route path="/" element={<Home/>} animate={true} mainnavigation={true}/>
                    <Route path="/eventlist" element={<HomeDefaultList/>} animate={true} mainnavigation={true}/>
                    <Route path="/about" element={<Kontakt/>} animate={true}/>
                    <Route path="/creativework/:id" element={<CreativeWork/>} animate={true} mainnavigation={true}/>
                    <Route path="/performingartstheatre" element={<HostingOrganization/>} animate={true} mainnavigation={true}/>
                    <Route path="/performingartstheatre/:id" element={<OrganizationItem/>} animate={true} mainnavigation={true}/>
                    <Route path="/performingartsgroup" element={<PerformingOrganization/>} animate={true} mainnavigation={true}/>
                    <Route path="/performingartsgroup/:id" element={<OrganizationItem/>} animate={true} mainnavigation={true}/>
                    <Route path="/fthtv" element={<Vimeoblock/>} animate={true} mainnavigation={true}/>
                    <Route path="/imprint" element={<Imprint/>} animate={true} mainnavigation={true}/>
                    <Route path="/privacy" element={<Privacy/>} animate={true} mainnavigation={true}/>
                    <Route
                        path="/export"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <HomeDefaultList />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/export-current-month"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <HomeDefaultListCurrent />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/app"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <Dashboard />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/app/creativework/:id"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <EditCreativeWork />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/app/addcreativework"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <AddCreativeWork />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="*"
                        element={<NotFound/>}
                    />
                </Routes>
            </main>
            <Footer/>
            <ScrollToTop/>
        </>
    );
}

export default App;

