import React, {Component, useRef} from 'react';
import {getCollectionUrl} from "../utils";
import {NavLink} from "react-router-dom";
import {format, endOfYesterday, startOfMonth,endOfMonth} from 'date-fns';
import {de} from 'date-fns/locale';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
class Eventlistexport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            filterByID: props.filterByID,
            startdate: format(startOfMonth(new Date(new Date().getFullYear(), new Date().getMonth()+1, 1,15,0,1)), 'yyyy-MM-dd', {
                locale: de
            }),
            enddate: format(endOfMonth(new Date(new Date().getFullYear(), new Date().getMonth()+1, 1)), 'yyyy-MM-dd', {
                locale: de
            }),
        }
        this.renderShowmore.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getData() {
        console.log(this.props.filterByID);
        let customfilter= null
        if(this.props.filterByID!==false){
            customfilter={ $and: [{startDate: {$gte: this.state.startdate}},{CreativeWork: this.props.filterByID}] }
        }else{
            customfilter={
                $and: [
                    {startDate: {$gte: this.state.startdate}},
                    {startDate: {$lte: this.state.enddate}}
                ] }
        }
        fetch(getCollectionUrl('Event'), {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                limit: 1000,
                populate: 1,
                skip: null,
                sort: {
                    startDate:1,
                    time:1,
                    "creativeWork.name":1
                },
                filter: customfilter
            }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allevents: data.entries,
                    total_chunks: data.entries.length,
                    current_chunks: 0,
                    eventlist: data.entries,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    renderTime = function renderTime(time) {
        return <>
            {(time.substring(0, 5) !== "00:00") ?
                <>{time.substring(0, 5)}</>
                : <>—</>
            }
        </>
    }
    renderTicketURL = function renderTicketURL(data) {
        let ticketurl=null;



        if(
            typeof data.Location === "object" &&
            data.Location !== null
        ){
            if(data.Location.ticketurl !== null){
                ticketurl=data.Location.ticketurl;
            }
            if(
                typeof data.Location.HostingOrganization === "object" &&
                data.Location.HostingOrganization !== null &&
                data.Location.HostingOrganization.ticketurl !== null
            ){
                ticketurl=data.Location.HostingOrganization.ticketurl;
            }
        }

        if(
            typeof data.Location === "object" &&
            data.Location !== null &&
            data.Location.ticketurl !== null
        ){
            ticketurl=data.Location.ticketurl;
        }
        if(
            typeof data.Location.HostingOrganization === "object" &&
            data.Location.HostingOrganization !== null &&
            data.Location.HostingOrganization.ticketurl !== null
        ){
            ticketurl=data.Location.HostingOrganization.ticketurl;
        }
        if(
            typeof data.CreativeWork === "object" &&
            data.CreativeWork !== null &&
            data.CreativeWork.ticketurl !== null
        ){
            ticketurl=data.CreativeWork.ticketurl;
        }
        return <>
            {(ticketurl !== null) &&
                <div
                    className={'mt-md-2 col-6 col-md-12 offset-xl-0  col-lg-6 col-xl-12 p-0 ps-xl-0 tickets'}>
                    <a href={ticketurl}
                       className={'btn btn-outline-dark rounded-pill text-center mb-md-2 mb-xl-2 tickets'}><b>TICKETS</b></a>
                </div>
            }
        </>
    }
    //Interner / externer Link //REACT_APP_HOST
    checkInternalLink(link) {
        const host = process.env.REACT_APP_HOST.replace("https://", "");

        function isExternalLink() {
            const tmp = document.createElement('a');
            tmp.href = link;
            return tmp.host !== host;
        }

        return !isExternalLink();
    }

    renderShowmore = function renderShowmore(show) {
        if (show)
            return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                <button className={'btn btn-lg btn-dark text-uppercase has-secondary-font fw-bold'}
                        onClick={this.handleClick}>+ Weitere anzeigen
                </button>
            </div>;
        return null;
    }

    handleClick(param) {
        this.setState((state) => {
            if (state.current_chunks < state.total_chunks) {
                const pusharray = state.eventlist.concat(state.allevents[(state.current_chunks + 1) * this.state.chunkSize]);
                return {
                    current_chunks: state.current_chunks + 1,
                    eventlist: pusharray
                }
            }
            return null;
        });
    }
    handleExport() {
        let csv=[]
        const headers = document.querySelectorAll("table thead tr");
        const rows = document.querySelectorAll("table tbody tr");
        console.log(headers);
        console.log(rows);
        for (var i = 0; i < headers.length; i++) {
            var header = [], cols = headers[i].querySelectorAll("th.export");

            for (var j = 0; j < cols.length; j++)
                header.push(cols[j].innerText);
                csv.push(header.join(";"));
        }
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td.export");

            for (var j = 0; j < cols.length; j++)
                row.push(cols[j].innerText);
                csv.push(row.join(";"));
        }

        csv = csv.join("\n")
        // Download CSV file
        var csvFile;
        var downloadLink;

        // CSV file
/*
        csvFile = new Blob([csv], {type: "text/csv"});
*/
        csvFile = new Blob(["\uFEFF"+csv], {
            type: "text/csv; charset=utf-8"
        });

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = 'events.csv';

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = "none";

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }

    exportTableToCSV(filename) {
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        console.log('length');
        console.log(rows.length);
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("tbody td.export");

            for (var j = 0; j < cols.length; j++)
                row.push(cols[j].innerText);

            csv.push(row.join(";"));
        }

        // Download CSV file
/*        this.downloadCSV(csv.join("\n"), filename);*/
    }


    render() {
        return (
            <section className={'container-fluid list'}>
                <div className="table-responsive">

                <table className={'table  table-sm small table-bordered '}>
                    <thead>
                        <tr>
                            <th className={'export'}>Datum</th>
                            <th className={'export'}>Zeit</th>
                            <th className={'export'}>Premiere</th>
                            <th className={'export'}>Performende</th>
                            <th className={'export'}>Titel</th>
                            <th className={'export'}>Untertitel</th>
                            <th className={'export'}>Altersempfehlung</th>
                            <th className={'export'}>Ort</th>
                            <th>Bild</th>
                        </tr>
                    </thead>
                <tbody>
                {this.state.eventlist.map((event, index) => (
                    <tr key={'event-' + index}>
                        <td className={'export no-break'}>
                                {format(new Date(event.startDate), 'iiiiii dd.MM.yyyy', {
                                    locale: de
                                })}
                        </td>
                        <td className={'export no-break'}>{this.renderTime(event.time)}</td>
                        <td className={'export'}>
                            {event.premiere &&
                            <>Premiere</>
                        }</td>
                        <td className={'export'}>{(typeof event.CreativeWork.performingGroup === "object" && event.CreativeWork.performingGroup !== null) ?
                                <> {event.CreativeWork.performingGroup.name}</>
                            : <>{event.CreativeWork.alternatePerformer}</>

                        }</td>
                                <td className={'export'}>
                                    {event.CreativeWork.name}
                                </td>
                        <td className={'export'}>
                            {event.CreativeWork.slogan}
                        </td>
                        <td className={'export no-break'}>
                            {event.CreativeWork.typicalAgeRange !== undefined &&
                                <>
                                {event.CreativeWork.typicalAgeRange!==null &&

                                        <>ab {event.CreativeWork.typicalAgeRange.name} Jahre</>
                                }
                                </>
                            }
                        </td>
                        <td className={'export'}>
                            {(typeof event.Location.HostingOrganization === "object" && event.Location.HostingOrganization !== null) ?
                                <>
                                    {event.Location.HostingOrganization.name}
                                </>
                                : <>
                                    {event.Location.name}
                                </>

                            }
                        </td>
                        <td>
                            {(typeof event.CreativeWork.image === "object" && event.CreativeWork.image !== null) ?
                                <a href={process.env.REACT_APP_API_URL + event.CreativeWork.image.path} download={event.CreativeWork.name}>
{/*                                <img width={100} alt={event.CreativeWork.name} className={'img-fluid'}
                                     src={process.env.REACT_APP_API_URL + event.CreativeWork.image.path}/>*/}
                                    {process.env.REACT_APP_API_URL + event.CreativeWork.image.path}</a>
                                :
                                <>&nbsp;</>
                            }

                        </td>
                    </tr>
                ))}
                {(this.state.loading===false) &&
                    <tr key={'eventexport'}>
                        <td colSpan={9}>
                            <button className={'btn btn-primary'} onClick={this.handleExport}>Export</button>
                        </td>
                    </tr>
                }
                </tbody>
                </table>
                </div>
            </section>
        );
    }
}

export default Eventlistexport;

