import React, {useState, useEffect} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {getCollectionUrl} from "../../utils";
import useHyphen from 'react-use-hyphen';
import Eventlist from "../../components/Eventlist";
import {Helmet} from "react-helmet";
import {Image} from "@wbe/lazy-image";

const CreativeWork = _ => {
    let params = useParams();
    const _id = params.id;
    const [data, setData] = useState({});
    const [filterByID, setfilterByID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {Hyphen} = useHyphen();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                let pathArray = window.location.pathname.split('/');
                setfilterByID(pathArray[2]);
                const response = await fetch(getCollectionUrl('CreativeWork'), {
                    mode: 'cors',
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        filter: {_id: pathArray[2]},
                        sort: {'startdate': -1},
                        populate: 1, // resolve linked collection items
                    })
                })
                const json = await response.json();

                setData(json.entries[0]);
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

    }, [_id]);
/*    function copyright(image) {
        function isNullOrUndefined(value) {
            return value === undefined || value === null;
        }

        if (isNullOrUndefined(image.meta)) {
            return false;
        } else {
            if (isNullOrUndefined(image.meta.title)) {
                return false;
            } else {
                return image.meta.title
            }
        }
    }*/
    function renderWYSIWG(strToDecode) {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(`<!doctype html><body>${strToDecode}`, 'text/html').body.textContent;
        console.log(decodedString);
        return decodedString;
    }
    data.cp='';
/*    if(typeof data.image.meta!=="undefined"){
        if(data.image.meta.title!== null && data.image.meta.title!== ''){
            data.cp='©'+data.image.meta.title;
        }else{
            data.cp='';
        }
        setData(data);
    }*/

    return <div className={'creativework totop'}>
        <Helmet>
            <title>{data.name + '– Freie Theater Hannover'}</title>
        </Helmet>
        <section className={'container-fluid p-0 mt-0'}>
            <div className={'row g-0'}>
                <div className={'col-12 p-0'}>
                    <div className={'position-relative bg-black'}>
                        <div className={'text-white'}>
                            {(typeof data.image === "object" && data.image !== null) ?
                                <div className={'img-wrapper card-image'}>
                                    <Image
                                        srcPlaceholder={'/static/images/placeholder.png'}
                                        src={process.env.REACT_APP_API_URL + '/api/cockpit/image?token=' + process.env.REACT_APP_API_TOKEN + '&src=' + data.image.path + '&w=1920&h=1080&m=thumbnail&mime=image/webp&q=80&o=1'}
                                        alt={data.name}
                                        width={1920}
                                        height={1080}
                                        className={'img-fluid'}
                                    />

                                   {/* <div className={'copyright text-lowercase'}><small>{data.cp}</small></div>*/}
                                </div>
                                :
                                <span>&nbsp;</span>
                            }
                            <div className={'position-absolute card-title'}>
                                <h2 className={'h1 fz-140 text-uppercase hyphenation h-100 w-100 d-flex flex-column'}>
                                    <span className={'d-flex mb-auto mt-auto ms-auto me-auto text-center'}>
{/*
                                        <b className={'d-block w-100 mb-lg-5 pb-lg-5'}>{data.name}</b><br/>
*/}
                                       {/* {this.copyright(data.image)}*/}
{/*                                        {(typeof data.performingGroup === "object" && data.performingGroup !== null) ?
                                            <small className={'d-block w-100'}>{data.performingGroup.name}</small> : <small  className={'d-inline-block'}>{data.alternatePerformer}</small>
                                        }*/}
                                        </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={'mt-0 pt-0'}>
            <div className={'container-fluid'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-lg-12 infotext pt-5 pb-5'}>
                            <b className={'pb-4'}>
                                {(typeof data.performingGroup === "object" && data.performingGroup !== null) ?
                                    <NavLink
                                        className={`lead text-uppercase fw-normal text-black`}
                                        to={'/performingartsgroup/' + data.performingGroup._id}
                                        title={data.performingGroup.name}
                                    >
                                        {data.performingGroup.name}
                                    </NavLink>
                                    : <span
                                        className={'lead text-uppercase fw-normal'}>{data.alternatePerformer}</span>

                                }
                            </b>
                            <div className={'h4 mt-2 mb-5 text-uppercase'}>
                                {data.name}<br/>{data.slogan}<br/>
                                {(typeof data.Genre !== 'undefined' && data.Genre !== null) &&
                                    <div className={'genre-list mt-3 d-inline-block'}>
                                        {data.Genre.map((genre, index) => (

                                            <span className={'h6 mb-0 text-normal d-inline-block me-2'} key={'event-' + index}>{genre.name}<i className={'coma'}>,</i></span>

                                                ))}
                                    </div>
                                }
                                {(typeof data.typicalAgeRange !== 'undefined' && data.typicalAgeRange !== null) &&
                                    <span className={'h6 text-normal d-inline-block me-2'}>– ab {data.typicalAgeRange.display}</span>
                                }
                            </div>

                            <Hyphen>
                                <div className={'text-holder hyphennation'}
                                     dangerouslySetInnerHTML={{__html: (renderWYSIWG(data.description))}} />
                            </Hyphen>
                        </div>
                    </div>
                </div>

                <div className={'col-lg-12'}>
                    <div className={'container-fluid bg-gray'}>
                        <div className={'row'}>
                            {(isLoading === false) &&
                                <>
                                <article>
                                    <Eventlist filterByID={filterByID}/>
                                    <div className={'col-12 mb-5 pb-5'}>

                                    </div>
                                </article>
{/*                                <hr/>

                                {
                                    <div className={'col'}>
                                        <a href={process.env.REACT_APP_API_URL + data.image.path}>Bild-Download</a>
                                    </div>
                                }*/}
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

};

export default CreativeWork;

