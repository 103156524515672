import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import useHyphen from 'react-use-hyphen';
import {Helmet} from "react-helmet";
import Vimeo from '@u-wave/react-vimeo';

const Vimeoblock = _ => {
    let params = useParams();
    const _id = params.id;
    const [data, setData] = useState({});
    const [filterByID, setfilterByID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {Hyphen} = useHyphen();
    return <div className={'organization'}>
        <Helmet>
            <title>{'FTH.tv – Freie Theater Hannover'}</title>
        </Helmet>
        <section className={'mt-0 pt-0 bg-black vh-100'}>
            <div className={'container pb-5 text-white'}>
                <div className={'row'}>
                    <div className={'col-12 pt-5'}>
                        <h1 className={'fz-140 text-uppercase mt-5 hyphenation'}>FTH.tv</h1>
                    </div>
                </div>
            </div>
            <div className={'container-fluid border-top border-3'}>
                <div className={'row'}>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                        <Vimeo
                            video={'410618422'}
                            dnt={true}
                            responsive={true}
                            showTitle={false}
                            showPortrait={false}
                            showByline={false}
                        />
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                    <Vimeo
                        video={'410629840'}
                        dnt={true}
                        responsive={true}
                        showTitle={false}
                        showPortrait={false}
                        showByline={false}
                    />
                </div>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                        <Vimeo
                            video={'410624575'}
                            dnt={true}
                            responsive={true}
                            showTitle={false}
                            showPortrait={false}
                            showByline={false}
                        />
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                        <Vimeo
                            video={'410609088'}
                            dnt={true}
                            responsive={true}
                            showTitle={false}
                            showPortrait={false}
                            showByline={false}
                        />
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                        <Vimeo
                            video={'410635599'}
                            dnt={true}
                            responsive={true}
                            showTitle={false}
                            showPortrait={false}
                            showByline={false}
                        />
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                    </div>
                </div>
            </div>
        </section>
    </div>

};

export default Vimeoblock;

