import React, {Component} from 'react';
import {getCollectionUrl} from "../utils";
import {NavLink} from "react-router-dom";
import {format,endOfYesterday } from 'date-fns';
import {de} from 'date-fns/locale';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
class Eventlist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            filterByID: props.filterByID,
            startdate: format(endOfYesterday(), 'yyyy-MM-dd', {
                locale: de
            })
        }
        this.renderShowmore.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }


    getData() {
        let customfilter= null
        if(this.props.filterByID!==false){
            customfilter={ $and: [{startDate: {$gt: this.state.startdate}},{CreativeWork: this.props.filterByID}, {published:1}] }
        }else{
            customfilter={ $and: [{startDate: {$gt: this.state.startdate}}, {published:1}] }
        }
        fetch(getCollectionUrl('Event'), {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                limit: 1000,
                populate: 1,
                skip: null,
                sort: {
                    startDate:1,
                    time:1,
                    "creativeWork.name":1
                },
                filter: customfilter
            }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allevents: data.entries,
                    total_chunks: data.entries.length,
                    current_chunks: 0,
                    eventlist: data.entries,
                    loading: false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    renderTime = function renderTime(time) {
        return <>
            {(time.substring(0, 5) !== "00:00") ?
                <span><b>{time.substring(0, 5)} Uhr</b></span>
                : <span><b>—</b></span>
            }
        </>
    }
    renderTicketURL = function renderTicketURL(data) {
        let ticketurl=null;



        if(
            typeof data.Location === "object" &&
            data.Location !== null
        ){
            if(data.Location.ticketurl !== null){
                ticketurl=data.Location.ticketurl;
            }
            if(
                typeof data.Location.HostingOrganization === "object" &&
                data.Location.HostingOrganization !== null &&
                data.Location.HostingOrganization.ticketurl !== null
            ){
                ticketurl=data.Location.HostingOrganization.ticketurl;
            }
        }

        if(
            typeof data.Location === "object" &&
            data.Location !== null &&
            data.Location.ticketurl !== null
        ){
            ticketurl=data.Location.ticketurl;
        }
        if(
            typeof data.Location.HostingOrganization === "object" &&
            data.Location.HostingOrganization !== null &&
            data.Location.HostingOrganization.ticketurl !== null
        ){
            ticketurl=data.Location.HostingOrganization.ticketurl;
        }
        if(
            typeof data.CreativeWork === "object" &&
            data.CreativeWork !== null &&
            data.CreativeWork.ticketurl !== null
        ){
            ticketurl=data.CreativeWork.ticketurl;
        }
        return <>
            {(ticketurl !== null) &&
                <div
                    className={'mt-md-2 col-6 col-md-12 offset-xl-0  col-lg-6 col-xl-12 p-0 ps-xl-0 tickets'}>
                    <a href={ticketurl}
                       className={'btn btn-outline-dark rounded-pill text-center mb-md-2 mb-xl-2 tickets'}><b>TICKETS</b></a>
                </div>
            }
        </>
    }
    //Interner / externer Link //REACT_APP_HOST
    checkInternalLink(link) {
        const host = process.env.REACT_APP_HOST.replace("https://", "");

        function isExternalLink() {
            const tmp = document.createElement('a');
            tmp.href = link;
            return tmp.host !== host;
        }

        return !isExternalLink();
    }

    renderShowmore = function renderShowmore(show) {
        if (show)
            return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                <button className={'btn btn-lg btn-dark text-uppercase has-secondary-font fw-bold'}
                        onClick={this.handleClick}>+ Weitere anzeigen
                </button>
            </div>;
        return null;
    }

    handleClick(param) {
        this.setState((state) => {
            if (state.current_chunks < state.total_chunks) {
                const pusharray = state.eventlist.concat(state.allevents[(state.current_chunks + 1) * this.state.chunkSize]);
                return {
                    current_chunks: state.current_chunks + 1,
                    eventlist: pusharray
                }
            }
            return null;
        });
    }

    render() {
        return (
            <section className={'container list'}>
                {this.state.eventlist.map((event, index) => (
                    <div key={'event-' + index} className={'row mb-0 bg-white pt-4 pb-4 border-bottom border-5 border-dark' + (event.premiere ? ' premiere' : '')}>
                        <div className={'col-12'}>
                            <div className={`row`}>
                                <div className={`eventlist-date col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 d-flex d-flex-column p-0 text-uppercase text-center event-date-wapper`}>
                                    <div
                                        className={'d-inline-flex mt-auto mb-auto w-100 event-date '}>
                                        <p className={"mt-auto mb-auto pt-4 pb-2 text-center w-100"}><b
                                            className={'h2 mb-0'}>
                                            {format(new Date(event.startDate), 'iiiiii dd.MM', {
                                                locale: de
                                            })}
                                        </b><br />
                                            {this.renderTime(event.time)}
                                            {event.premiere &&
                                                <span><br /><b>Premiere</b></span>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className={'bg-white col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 p-0 event-img'}>
                                    <NavLink
                                        className={`text-decoration-none text-dark`}
                                        to={'/creativework/' + event.CreativeWork._id}
                                        title={event.CreativeWork.name}
                                    >
                                        <div className={'position-relative'}>
                                            <div className={'text-white'}>
                                                {(typeof event.CreativeWork.image === "object" && event.CreativeWork.image !== null && event.CreativeWork.image.styles!== undefined) ?
                                                    <div className={'img-wrapper card-image'}>
                                                        <img alt={event.CreativeWork.name} className={'img-fluid'}
                                                             src={process.env.REACT_APP_API_URL + event.CreativeWork.image.styles[0].path}/>
                                                    </div>
                                                    :
                                                    <span>&nbsp;</span>
                                                }
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className={'bg-white col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-2 pt-2 ps-xl-4 pe-xl-4 d-flex flex-column pb-4 pb-lg-0'}>
                                    <div className={' mt-auto '}>
                                        {(typeof event.CreativeWork.performingGroup === "object" && event.CreativeWork.performingGroup !== null) ?
                                            <NavLink
                                                className={`mb-0 pt-2 text-decoration-none text-black`}
                                                to={'/performingartsgroup/' + event.CreativeWork.performingGroup._id}
                                                title={event.CreativeWork.performingGroup.name}
                                            >
                                                <span className={'text-uppercase'}> {event.CreativeWork.performingGroup.name}</span>
                                            </NavLink>
                                            : <p className={'mb-0 pt-2'}><span className={'text-uppercase'}>{event.CreativeWork.alternatePerformer}</span></p>

                                        }
                                        <NavLink
                                            className={`text-decoration-none text-dark`}
                                            to={'/creativework/' + event.CreativeWork._id}
                                            title={event.CreativeWork.name}
                                        >
                                            <h3 className={'pt-3 pt-md-0 mb-0 text-uppercase'}>{event.CreativeWork.name}</h3>
                                        </NavLink>

                                        <p className={'mb-0 line-clamp-2'}><b>{event.CreativeWork.slogan}</b></p>
                                    </div>
                                    {/*<p className={'mb-auto'}>{event.Location.name}</p>*/}
                                    {(typeof event.Location.HostingOrganization === "object" && event.Location.HostingOrganization !== null) ?
                                        <NavLink
                                            className={`mb-auto  line-clamp-2  text-decoration-none text-black`}
                                            to={'/performingartstheatre/' + event.Location.HostingOrganization._id}
                                            title={event.Location.HostingOrganization.name}
                                        >
                                            {event.Location.HostingOrganization.name}
                                            {/*{(this.props.filterByID !== false)  &&*/}
                                            {/*    <>, {event.Location.HostingOrganization.street}</>*/}
                                            {/*}*/}

                                        </NavLink>
                                        : <p className={'mb-auto  line-clamp-2'}>
                                            <OverlayTrigger trigger="click" placement="right" overlay={<Popover id="popover-basic"><Popover.Body>{event.Location.name}<br/>{event.Location.street}<br/>{event.Location.zip} {event.Location.city}<br />
                                                {(event.Location.street!==null) &&
                                                <a className={'text-white'} href={'https://www.google.de/maps/dir//' + encodeURI(event.Location.street + ', ' + event.Location.zip + ' ' + event.Location.city)}>Route</a>
                                                }
                                            </Popover.Body></Popover>}>
                                                <Button variant={'link'} className={'mb-auto text-start line-clamp-2  text-decoration-none text-black'}>{event.Location.name}</Button>
                                            </OverlayTrigger>
                                        </p>

                                    }

                                </div>
                                <div className={'bg-primary d-none d-lg-flex col-lg-6 d-xl-none'}/>
                                <div className={'bg-white col-sm-12 col-md-6 col-lg-6 col-xl-2 p-0'}>
                                    <div className={'event-btn h-100 pt-3 pt-md-0 pt-xl-0 pb-3 position-relative'}>
                                        <div className={'d-md-flex flex-md-column h-100 m-0 text-md-center text-lg-start text-xl-center'}>
                                            <div className={"d-flex mb-auto mt-auto ps-lg-2 ps-xl-0"}>
                                                <div className={'row w-100 pe-1 ps-1 m-0'}>
                                                                                              {(this.props.filterByID === false) &&
                                                        <div className={'col-6 col-md-12  col-lg-6 col-xl-12 p-0 morinfo'}>


                                                                <NavLink
                                                                    className={`btn btn-dark rounded-pill text-center`}
                                                                    to={'/creativework/' + event.CreativeWork._id}
                                                                    title={event.CreativeWork.name}
                                                                >
                                                                    <b>MEHR</b>
                                                                </NavLink>

                                                        </div>
                                                    }
                                                    <>{this.renderTicketURL(event)}</>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        );
    }
}

export default Eventlist;

